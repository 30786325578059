import { useState } from "react";
import { Space,Divider } from "antd";
import dvedio from "@/assets/d_vedio.png"
import ship_icon from "@/assets/p_icon.svg"
import port_icon from "@/assets/p_icon_port.svg"
import warehouse_icon from "@/assets/p_icon_warehouse.svg"

const RenderStorageHouse = ({ item, showDetail, storageType='', billList=[],handleVedio ,title}) => {
const [clickedIndex, setclickedIndex] = useState(-1);
const divider_color = ()=>{
  console.log(storageType,'storageType');
  if(storageType=="ship"){
      return '#E8C575'
  }
  if(storageType=="port"){
      return '#E8C575'
  }
  if(storageType=="warehouse"){
      return '#53FF6D'
  }
}
  return <Space size={[0, 0]} direction="vertical" className={`storage_container storage_${storageType}_container`}>
    <div className="storage_name_btn">
      <span>{title}</span>
      <span className="detail_btn" onClick={showDetail} style={{cursor:"pointer"}}> 查看详情</span>
    </div>
    <div className="goods_info_container">
    {storageType}
    {billList.length==0?<div className="goods_info">
      <span>{item?.title}: </span>
      <span> <span className="goods_num">{item?.number} </span>{item?.itemUnit || "吨"}</span>
    </div>:billList.map((m,billIndex)=>
    
    (<div key={m?.code} className="goods_info bill_item" onClick={()=>setclickedIndex(billIndex)}>
      <span> {m?.code}</span>
      {billIndex==clickedIndex&&<div className="bill_item_detail" style={{border:'1px solid '+divider_color()}}>
        <h4>{m?.code}</h4>
        <span>{m?.title}</span>
        {item?.number&&<span> {item?.number} {item?.itemUnit || "吨"}</span>}
        <span>{m?.warehouse}</span>
        <Divider  style={{ backgroundColor: divider_color(), height: 1,margin:"12px 0" }} />
        <div onClick={()=>handleVedio(m?.code)} style={{textAlign:'center',cursor:"pointer"}}><img src={dvedio} /> 实时监控</div>
        </div>}
    </div>))}
    </div>
  </Space>
}

export default RenderStorageHouse;