import React, { useEffect } from 'react';
import titleTwo from '@/assets/titleTwo.png'
import ArrowShape from '@/assets/ArrowShape.png'
import './leftModule.scss';
import {currentEnvirement} from "@/utils/common"
export default function leftModule({info}) {
 
  return (
    <div className='leftModule'>
      <div className='leftModuleTop'>
        <span className='Overview'>数据总览</span>
      </div>
      <div className='leftModuleTrade'>
      <div className='QuantityOfGoodsTitle'>贸易总额</div>
        <div className='QuantityCenter'>
          <span className='QuantityCenterLeft'>{info.tradeAmount||'--'}</span>
          <span className='tradeBoxDataUnit'>/ 万元</span>
        </div>
        {/* <div>
          <span className='YoYLastYe'>同比去年</span>
          <span className='YoYLastYeRight'>15%</span>
        </div> */}
      </div>

      <div className='QuantityOfGoods'>
        <div className='QuantityOfGoodsTitle'>货物数量</div>
        <div className='QuantityCenter'>
          <span className='QuantityCenterLeft'>{info.tradeWeight||'--'}</span>
          <span className='tradeBoxDataUnit'>/ 吨</span>
        </div>
        {/* <div>
          <span className='YoYLastYe'>同比去年</span>
          <span className='YoYLastYeRight'>5%</span>
        </div> */}
        <div className='QuantityOfGoodsBottom'>
          <div className='InTransitImg'>
            <div className='InTransitImgTop'>{info?.ladingTransit||'--'}</div>
            <div className='InTransitImgTopBum'>在途/吨</div>
          </div>
          <div className='InTransitImg'>
            <div className='InTransitImgTop'>{info.onWarehouse||'--'}</div>
            <div className='InTransitImgTopBum'>到仓/吨</div>
          </div>
        </div>
      </div>


      <div className='leftModuleTop leftModulWidht'>
        <span className='Overview'>运单周期</span>
      </div>
      <div className='waybillCycle'>
        <div className='cycle'>
          <div className='cycleData'>{info.business||0}</div>
          <div className='cycleDataBom'>运单数</div>
        </div>
        <div className='cycleBottom'>
          <div >
            <div className='averagePeriod'>平均周期</div>
            <div className='Period'><span className='periodData'>{info.cycle?.average||0}</span><span className='day'>天</span></div>
          </div>
          <div className={ currentEnvirement()=="PC"?'cycleBottomCenter_pc':'cycleBottomCenter'}>
            <div className='averagePeriod'>最大周期</div>
            <div className='Period'><span className='periodData'>{info.cycle?.max||0}</span><span className='day'>天</span></div>
          </div>
          <div className={ currentEnvirement()=="PC"?'cycleBottomBottom_pc':'cycleBottomBottom'}>
            <div className='averagePeriod'>最小周期</div>
            <div className='Period'><span className='periodData'>{info.cycle?.min||0}</span><span className='day'>天</span></div>
          </div>
        </div>
      </div>
    </div>
  );
}
