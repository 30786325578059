import { useEffect, useState } from "react";
import CountUp from "../react-countUp/react-countup/count/index";
import LeftScrollBoardCopy from "./leftScrollBoardCopy";
// warehouseAddressRight
import WarehouseAddressRight from "./warehouseAddressRight";
import Monthly from "./monthly";
import "./leftModule.scss";
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export default function LeftTradeOverview(props) {

  const {
    tradeVolume,
    tradeName,
  } = props.data;
  const { screenList } = props || {}


  const totalTradeValue = Number(screenList?.totalTradeValue || '0.00')
  const totalTradeValueData = totalTradeValue?.toLocaleString()?.split("")

  const [upOutFlag, setUpOutFlag] = useState(false)
  const [isIndex, setIsIndex] = useState(0)
  console.log('screenList8777777777', screenList?.warehouseInventories?.[isIndex]?.productQuantities);

  useEffect(() => {
    // if (screenList?.warehouseInventories?.[isIndex]?.productQuantities) {
    //   // 构建新的 data 数组
    //   const newData = screenList.warehouseInventories[isIndex].productQuantities.map((item) => ({
    //     name: item.variety,
    //     value: parseFloat(item.quantity),
    //   }));
    //   const newCategoryStorageBox = [...categoryStorageBox];
    //   if (newCategoryStorageBox[isIndex]) {
    //     newCategoryStorageBox[isIndex].data = newData;
    //   } else {
    //     newCategoryStorageBox.push({ data: newData });
    //   }
    //   setCategoryStorageBox(newCategoryStorageBox);
    // }

    // const updatedQuantities = screenList?.warehouseInventories?.[isIndex]?.productQuantities?.map((item) => {  
    //   if (item) {
    //     item.name = item.variety;
    //     item.value = item.quantity;
    //     item.newProperty = 'someValue';
    //   } 
    //   return item;
    // });
    // if (updatedQuantities) {  
    //   screenList.warehouseInventories[isIndex].productQuantities = updatedQuantities; 
    // }
  }, [props.typeTabs, props.screenList, isIndex]);

  const OverviewVal = {
    transit: "3760",
    inventory: "9480",
    entrust: "1840",
  };
  const categoryStorageBox = [
    {
      name: "满洲里三发木业有限公司",
      address: '内蒙古满洲里市满西公路以西外环路以南',
      id: 0,
      value: '',
      total: '422694',
      data: [
        {
          name: '锌精矿',
          value: 18860,
          itemStyle: {
            color: '#FFD15C',
          },
        },
        {
          name: '铅精矿',
          value: 26092,
          itemStyle: {
            color: '#07D1FA',
          }
        },
      ]
    }, {
      name: "龙游东仓",
      address: '浙江省衢州市龙游县铁路货场1-2',
      id: 0,
      value: '',
      total: '1299',
      data: [
        {
          name: '纸浆',
          value: 3000,
          itemStyle: {
            color: '#FFD15C',
          },
        }

      ]
    },
    {
      name: "满洲里三发木业有限公司",
      address: '内蒙古满洲里市满西公路以西外环路以南',
      id: 0,
      value: '',
      total: '3000',
      data: [
        {
          name: '杂品',
          value: 400,
          itemStyle: {
            color: '#FFD15C',
          },
        },
        {
          name: '设备',
          value: 200,
          itemStyle: {
            color: '#20E6A4',
          }
        },
      ]
    },
  ]

  return (
    <div className="tj_left_data">
      <div className="tj_top_container">
        <div className="leftDataTitle"></div>
        <div className="tradeVolume">
          <div>总贸易货值(元)</div>
          <div>(货值:元)</div>
        </div>
        <div className="Trade">
          {totalTradeValueData.map((item, index) => {
            if (item === ",") {
              return (
                <span className="comma" key={item + index}>
                  {item}
                </span>
              );
            } else if (item === ".") {
              return (
                <span className="comma" key={item + index}>
                  {item}
                </span>
              );
            } else {
              return (
                <CountUp
                  start={0}
                  key={item + "-" + index}
                  end={Number(item)}
                  className="TradeData"
                // delay={2}
                // duration='30000'
                />
              );
            }
          })}
        </div>
        <div>
          <div className="category"> 月度运量</div>
          <div style={{ display: "flex" }} className="categoryBorder">
            <div className="categoryBorderLeft"></div>
            <div className="categoryBorderCenter"></div>
            <div className="categoryBorderRight"></div>
          </div>
        </div>
        <div className="MonthlyDataSituation">
          {
            props.typeTabs == 'my' ?
              <Monthly xAxisData={screenList?.monthlyVolume?.months} metaDate={screenList?.monthlyVolume} typeTabs={props.typeTabs} unit={screenList?.monthlyVolume?.unit} unit1={screenList?.monthlyVolume?.unit1} />
              : <Monthly xAxisData={screenList?.monthlyVolume?.months} metaDate={screenList?.monthlyVolume} typeTabs={props.typeTabs} unit={screenList?.monthlyVolume?.unit} unit1={screenList?.monthlyVolume?.unit1} />
          }

        </div>

        <div>
          <div className="category"> 实时运单</div>
          <div style={{ display: "flex" }} className="categoryBorder">
            <div className="categoryBorderLeft"></div>
            <div className="categoryBorderCenter"></div>
            <div className="categoryBorderRight"></div>
          </div>
        </div>
        <div className="HistogramChartBoard">
          <LeftScrollBoardCopy realTimeOrders={screenList?.realTimeOrders} />
        </div>
      </div>
      <div className="tj_bottom_container">
        <div className="operateSituation"></div>
        <div className="ProductInventorySituation">


          {/* {categoryStorageBox}  style={{position:'absolute',width:'100%'}}*/}
          <div  >
            <div className="categoryStorage"> {screenList?.warehouseInventories?.[isIndex]?.warehouseName}</div>
            <div style={{ display: "flex" }} className="categoryBorderStorage">
              <div className="categoryBorderLeftStorage"></div>
              <div className="categoryBorderCenterStorage"></div>
              <div className="categoryBorderRightStorage">
                <div className="downOutlinedStorage">
                  {/*  */}
                  {
                    upOutFlag ? <UpOutlined onClick={() => { setUpOutFlag(!upOutFlag) }} /> :
                      <DownOutlined onClick={() => { setUpOutFlag(!upOutFlag) }} />
                  }

                </div>
              </div>
            </div>
          </div>
          {
            // style={{ height: screenList?.warehouseInventories.length > 3 ? 'vh(121)' : 'auto'}}
            // ProductInventorySituationBox
            upOutFlag ? <div className={`ProductInventorySituationBox ${screenList?.warehouseInventories.length > 3 ? 'upHeight' : 'upHeightOut'}`} >
              {
                screenList?.warehouseInventories?.map((item, index) => {
                  return (
                    <div key={index}>

                      <div className="categoryStorageBoxLeft" onClick={() => {
                        setIsIndex(index)
                        setUpOutFlag(!upOutFlag)
                      }}>{item?.warehouseName}</div>


                    </div>
                  )
                })
              }
            </div> : ''
          }

          <div className="warehouseAddress">
            <div className="warehouseAddressLeft">
              <div className="warehouseAddressLeftOne">
                <div className="warehouseAddressLeftOneOne">仓库地址</div>
                <div className="warehouseAddressLeftOneTwo">{screenList?.warehouseInventories?.[isIndex]?.warehouseAddress}</div>
              </div>
              <div className="goodsTransit">
                <div style={{ display: "flex" }}>
                  <div className="goodsTransitLeft"></div>
                  <div className="goodsTransitBoxRight">
                    <div className="goodsTransitBoxRightOne">占地面积 </div>
                    <div className="goodsTransitNumber">
                      <span className="goodsNumber">{screenList?.warehouseInventories?.[isIndex]?.area}</span>{" "}
                      <span className="goodsUnit">(m²)</span>{" "}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="warehouseAddressRight">
             

              {
                screenList?.warehouseInventories?.[isIndex]?.productQuantities?.length > 0? <WarehouseAddressRight data={screenList?.warehouseInventories?.[isIndex]?.productQuantities} /> : <div className="warehouseAddressRightBottom">暂无货物</div>
              }
            
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}
