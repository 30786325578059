// 1. 判断token是否存在
// 2. 如果存在 直接正常渲染
// 3. 如果不存在 重定向到登录路由

// 高阶组件:把一个组件当成另外一个组件的参数传入
// 然后通过一定的判断 返回新的组件
import { getToken } from '@/utils'
import { Navigate } from 'react-router-dom'
// const LargeScreen = lazy(() => import('./pages/LargeScreen'))
// const JK = lazy(() => import('./pages/JK'))
import LargeScreen from '@/pages/LargeScreen'
import Demo from "@/pages/Demo"
function AuthComponent({ children }) {
  const token = window.location.href.split("?")[1]?.split("=")[1];
  const screen= localStorage.getItem('screen');
  if (token) {
    localStorage.setItem('token', token);
    localStorage.setItem('accessToken', token);
  }
  if (!token && !localStorage.getItem("accessToken")) {
    return <Navigate to="/login/" replace />
  } 
  if(localStorage.getItem('uid'))
   {
    if(screen!="null"&&screen!=null){
      return <Navigate to={localStorage.getItem('screen')} replace />
    }
    // return <><Demo/></>
    return <><LargeScreen/></>
  }
}

// <AuthComponent> <Layout/> </AuthComponent>
// 登录：<><Layout/></>
// 非登录：<Navigate to="/login" replace />

export {
  AuthComponent
}