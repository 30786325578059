import React, { useEffect, useState } from "react";
import "./rightModule.scss";
import {importTotal,exportTotal} from "../cargoShare"
import RightInventoryOverview from "./rightInventoryOverview";
import RightTradeOverview from "./rightTradeOverview";
import Situation from "./situation";
import PieDataChart from "./PieDataChart";
import ColumnarChartStore from "./columnarChartStore";
import {Space} from "antd"
export default function rightModule({
  info,
  typeTabs,
  setshowVedioInfo,
  currentSubsidiary,
  screenList
}) {
  // screenList={screenList}
  console.log(typeTabs, "数据444", screenList?.freightSummary?.destinationLists)
  const maxVolume = Math.max(...(screenList?.freightSummary?.destinationLists?.map(({ volume }) => volume) || [])) || null;
  
  const maxItem = screenList?.freightSummary?.destinationLists?.find(item => item?.volume == maxVolume) || null;

  const sourceListsMaxItem = Math.max(...(screenList?.freightSummary?.sourceLists?.map(({ volume }) => volume) || [])) || null;
  const sourceListsItem = screenList?.freightSummary?.sourceLists?.find(item => item?.volume == sourceListsMaxItem) || null;
  console.log(sourceListsItem?.sourceLocation, "maxVolume数据最大", sourceListsMaxItem);
  const { place, variety, port } = info;
  const [data, setdata] = useState({
    my: {
      OverviewVal: {
        transit: "14010",
        inventory: "3920",
        entrust: "6000",
      },
      OverviewOne: {
        xAxisData: ["海运", "铁运", "空运", "陆运"],
        legendData: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        seriesData: [
          200, 2014.408, 1533.276, 3723.7748, 3042.023, 1002.332, 6234.318,
          3472.494, 1064.034,
        ],
        seriesData1: [
          96771.741, 108003.888, 136843.636, 131999.035, 138811.873, 123932.288,
          118868.783, 82863.447, 46076.381,
        ],
        seriesData2: [],
        seriesData3: [],
      },
      //港口/堆场库存占比

      Proportion: {
        xAxisData: ["防城港", "宁波港", "连云港", "黄埔港", "青岛港"],
        legendData: ["在途货物", "库存货物", "委托加工"],
        seriesData: [
          {
            name: "在途货物",
            data: [1128, 564, 752, 376, 940],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
            //       return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
          {
            name: "库存货物",
            data: [444, 222, 296, 148, 370],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
          {
            name: "委托加工",
            data: [402, 201, 268, 134, 335],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
            //       return color[2] //color[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
        ],
      },
      warningData: {
        header: ["提单号", "港口", "到港日", "信用证到期日", "浮动盈亏"],
        data: [
          [
            "EGLV751231251417",
            "连云港",
            "03-12",
            '03-12<span style="color:#F5212D;">即将逾期</span>',
            '<span style="color:#F5212D;">+24000</span>',
          ],
          [
            "MEDUBG122326",
            "连云港",
            "02-13",
            '03-13<span style="color:#F5212D;">即将逾期</span>',
            '<span style="color:#F5212D;">+45320</span>',
          ],
          [
            "MEDUBG1231112",
            "防城港",
            "01-14",
            "--",
            '<span style="color:#50BA58;">-1500</span>',
          ],
          [
            "W231233226",
            "黄埔港",
            "02-25",
            "04-26",
            '<span style="color:#F5212D;">+24040</span>',
          ],
          [
            "MEDUL9231223",
            "黄埔港",
            "03-26",
            "04-17",
            '<span style="color:#50BA58;">-22300</span>',
          ],
          [
            "MEDUL9123122",
            "黄埔港",
            "03-17",
            "--",
            '<span style="color:#F5212D;">+24200</span>',
          ],
          [
            "MAERSK ALTAIR(散货)",
            "青岛港",
            "03-11",
            "05-19",
            '<span style="color:#50BA58;">正常</span>',
          ],
          [
            "EGLV142299991948",
            "宁波港",
            "04-19",
            "--",
            '<span style="color:#F5212D">+19000</span>',
          ],
          [
            "MEDUE3232232",
            "宁波港",
            "01-21",
            "--",
            '<span style="color:#F5212D">+1400</span>',
          ],
        ],
        columnWidth: [320, 100, 100, 200, 200, 120],
        align: ["left", "left", "left", "left", "left", "right"],
        headerBGC: "rgba(0, 123, 162, 0)",
        headerHeight: "40",
        oddRowBGC: "rgba(0, 123, 162, 0)",
        evenRowBGC: "rgba(0, 123, 162, 0)",
      },
    },
    gs: {
      OverviewVal: {
        transit: "3760",
        inventory: "1480",
        entrust: "1340",
      },
      OverviewOne: {
        xAxisData: ["海运", "铁运", "空运", "陆运"],
        legendData: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        seriesData: [],
        seriesData1: [51, 48, 189, 484, 205, 627, 97, 163, 211],
        seriesData2: [],
        seriesData3: [28, 78, 75, 90, 110, 249, 143, 207, 215],
      },
      //港口/堆场库存占比
      Proportion: {
        xAxisData: ["防城港", "宁波港", "连云港", "黄埔港", "青岛港"],
        legendData: ["在途货物", "库存货物", "委托加工"],
        seriesData: [
          {
            name: "在途货物",
            data: [1128, 564, 752, 376, 940],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
            //       return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
          {
            name: "库存货物",
            data: [444, 222, 296, 148, 370],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
          {
            name: "委托加工",
            data: [402, 201, 268, 134, 335],
            type: "bar",
            barWidth: "10%",
            // itemStyle: {
            //   normal: {
            //     color: function (params) {
            //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
            //       return color[2] //color[params.dataIndex]; //返回对应索引位置的颜色值
            //     }
            //   }
            // },
          },
        ],
      },
      warningData: {
        header: ["提单号", "港口", "到港日", "信用证到期日", "浮动盈亏"],
        data: [
          [
            "EGLV751231251417",
            "连云港",
            "01-12",
            "01-28",
            '<span style="color:#F5212D;">+24000</span>',
          ],
          [
            "MEDUBG122326",
            "连云港",
            "02-13",
            '01-17<span style="color:#F5212D;">即将逾期</span>',
            '<span style="color:#F5212D;">+45320</span>',
          ],
          [
            "MEDUBG1231112",
            "防城港",
            "01-14",
            "--",
            '<span style="color:#50BA58;">-1500</span>',
          ],
          [
            "W231233226",
            "黄埔港",
            "02-17",
            "02-18",
            '<span style="color:#F5212D;">+24040</span>',
          ],
          [
            "MEDUL9231223",
            "黄埔港",
            "02-19",
            "--",
            '<span style="color:#50BA58;">-22300</span>',
          ],
          [
            "MEDUL9123122",
            "黄埔港",
            "01-18",
            "--",
            '<span style="color:#F5212D;">+24200</span>',
          ],
          [
            "MAERSK ALTAIR(散货)",
            "青岛港",
            "04-19",
            "02-28",
            '<span style="color:#50BA58;">正常</span>',
          ],
          [
            "EGLV142299991948",
            "宁波港",
            "02-20",
            "--",
            '<span style="color:#F5212D">+19000</span>',
          ],
          [
            "MEDUE3232232",
            "宁波港",
            "02-21",
            "--",
            '<span style="color:#F5212D">+1400</span>',
          ],
        ],
        columnWidth: [240, 100, 100, 200, 200, 170],
        headerBGC: "rgba(0, 123, 162, 0)",
        headerHeight: "40",
        oddRowBGC: "rgba(0, 123, 162, 0)",
        evenRowBGC: "rgba(0, 123, 162, 0)",
      },
    },
  });
  let rateZoom = window.screen.width / 1920;
  const [selectedIndex, setSelectedIndex] = useState("import");
  const [goodsType, setgoodsType] = useState("importTotal");
  const [value, setValue] = useState([
    {
      name: "铁运",
      id: 1,
      code: "ty",
    },

    {
      name: "路运",
      id: 3,
      code: "ly",
    },
    {
      name: "海运",
      id: 0,
      code: "hy",
    },
    {
      name: "空运",
      id: 2,
      code: "ky",
    },
  ]);

  useEffect(() => {
   

    console.log(currentSubsidiary, "currentSubsidiary==");
  }, [screenList,currentSubsidiary?.label]);

  // 贸易总览
  return (
    <div style={{ width: "100%" }}>
      <div className="TotalFreightVolume">
        {/* <div className="FreightgoodsData"></div> */}
        <Space  size={[20 ,20]} style={{width:"100%",position:'relative',zIndex:99999}}>
          <div className="category" onClick={()=>setgoodsType('importTotal')}>进口货物运量总览</div>
          <div className="category" onClick={()=>setgoodsType('exportTotal')}>出口货物运量总览</div>
          </Space>
          <div style={{ display: "flex" }} className="category_border">
            <div className={goodsType=="importTotal"?'goodsType_bg_blue':"goodsType_bg_blue goodsType_move"}></div>           
          </div>
        <div className="FreightgoodsDataEchars">
          {/* {typeTabs == "my" ? data.my : data.gs} */}
          <Situation
            data={typeTabs == "my" ? data.my : data.gs}
            typeTabs={goodsType}
            unitType={goodsType=="importTotal"?screenList?.freightSummary?.importUnit
              :screenList?.freightSummary?.exportUnit}
            sData={goodsType=="importTotal"?screenList?.freightSummary?.freightLists1
              :screenList?.freightSummary?.freightLists}
            // screenList={screenList}
          />
        </div>
      </div>
      <div className="FreightWarningTable">
        <div className="FreightgoodsData"></div>

        {/* <div className="RoadTransportation"></div> */}

        <div className="tj_PieDataChartBox">
          <div className="tj_left_pie">
            <div>
              <div className="category">出口</div>
              <div style={{ display: "flex" }} className="categoryBorder">
                <div className="categoryBorderLeft"></div>
                <div className="categoryBorderCenter"></div>
                <div className="categoryBorderRight"></div>
              </div>
            </div>
            <div className="title">
              <span>热门目的地</span>
              <span>{maxItem?.destinationCountry}</span>
            </div>
            <div className="tj_pie_container">
              <PieDataChart destinationListsList={screenList?.freightSummary?.destinationLists} />
            </div>
          </div>
          <div className="tj_left_pie">
            <div>
              <div className="category">进口</div>
              <div style={{ display: "flex" }} className="categoryBorder">
                <div className="categoryBorderLeft"></div>
                <div className="categoryBorderCenter"></div>
                <div className="categoryBorderRight"></div>
              </div>
            </div>
            <div className="title">
              <span>热门货源地</span>
              <span>{sourceListsItem?.sourceLocation}</span>
            </div>
            <div className="tj_pie_container">
              <PieDataChart destinationListsList={screenList?.freightSummary?.sourceLists} />
            </div>
          </div>
        </div>
        <div>
        <Space  size={[20 ,20]} style={{width:"100%",position:'relative',zIndex:99999}}>
          <div className="category" onClick={()=>setSelectedIndex('import')}>进口货物占比</div>
          <div className="category" onClick={()=>setSelectedIndex('export')}>出口货物占比</div>
          </Space>
          <div style={{ display: "flex" }} className="category_border">
            <div className={selectedIndex=="import"?'category_bg_blue':"category_bg_blue move_position"}></div>           
          </div>
        </div>
        <div className="ColumnarChartBox">
          {screenList?.freightSummary&&<ColumnarChartStore
            rateZoom={rateZoom}
            selectedIndex={selectedIndex}
            typeTabs={typeTabs}
            screenList={selectedIndex==="import"?screenList?.freightSummary?.goodsRatios:screenList?.freightSummary?.goodsRatios1}
          />}
        </div>
      </div>
    </div>
  );
}
