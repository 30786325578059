import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function HistogramChart(props) {
  const {xAxisData,legendData,seriesData} = props.data
    const chartRef3 = useRef()
    var areaStyle = {
        silent: false,
        itemStyle: {
            normal: {
                color: "rgba(241, 28, 93, 0.05)",
            },
        },
    };
    const stackInfo = {};
    var option = {
        color: ['#60F4D9', '#2C58F1', '#F39422', ],
     
        tooltip: {
            trigger: "axis",
            // formatter: "{b}<br />{a0}: {c0}<br />{a1}: {c1}",
            axisPointer: {
                type: "shadow",
                label: {
                  backgroundColor: "#6a7985",
                },
            },
            textStyle: {
                fontSize: 10,
                color: '#007BA2',
                fontFamily: 'df'
            },
        },
        legend: {
            show: true,
            left: "right",
            data: legendData,//["在途货物", "库存货物", "委托加工", ],
            y: "5%",
            itemHeight: 8,
            right: 10,
            // y: 'center',
            icon: 'circle',
            itemWidth: 14,
            // itemHeight: 10,
            textStyle: {
                fontSize: 12,
                color: '#839AAA',
                fontFamily: 'df'
            },
        },
        grid: {
            //图表的位置
            top: "10%",
            left: "0%",
            right: "0%",
            bottom: "0%",
            containLabel: true,
        },
        yAxis: [
            {
                type: 'value',

                splitLine: { //网格线

                    lineStyle: {
                        color: '#313E42',
                        opacity: 0.2,
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true //隐藏或显示
                },
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#839AAA',
                        fontSize: 12,
                        fontFamily: 'df',
                    }
                },
            }
        ],
        xAxis: [
            {
                type: "category",
                data: xAxisData,
                // [
                //     "铜矿",
                //     "铅锌矿",
                //     "银矿",
                //     "铝矿",
                //     "铅精矿",
                // ],
                axisLabel: {
                    show: true,
                    textStyle: {
                        fontSize: 12,
                        color: '#839AAA',
                        fontFamily: 'df'
                    }
                }
            },
        ],
        series: seriesData,
        // [
        //     {
        //         name: "在途货物",
        //         type: "bar",
        //         stack: "总量",
        //         barWidth: "11px",
        //         data: [6700, 3760, 2440,680, 430,],
        //         // markArea: areaStyle,
        //     },
        //     {
        //         name: "库存货物",
        //         type: "bar",
        //         stack: "总量",
        //         data: [690, 1480, 590, 260, 900, ],
        //         // markArea: areaStyle,
        //     },
        //     {
        //         name: "委托加工",
        //         type: "bar",
        //         stack: "总量",
        //         data: [3230, 1340, 50, 1350,30, ],
        //         // markArea: areaStyle,
        //     },
        // ],

    };






    useEffect(() => {
        const chart = echarts.init(chartRef3.current)
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            chart.dispose()
        }
    }, [props.currentSubsidiary])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef3}></div>
    );
}
