import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

export default function HistogramChart(props) {
    const { xAxisData,metaDate}= {
        xAxisData: [
          "31月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
          "1月",
          "2月",
        ],
      
        metaDate: [708.10 , 1416.19 , 2124.29 , '', 2832.38 , 526.4, 283.24,1416.19 ,708.10 , 708.10 ,1416.19, 1416.19 ]
      }
    const chartRef1 = useRef()

    var option = {
    
        grid: {
            top: '15%',
            left: '0%',
            right: '1%',
            bottom: '12%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisTick: {
                    alignWithLabel: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',

                splitLine: { //网格线

                    lineStyle: {
                        color: '#313E42',
                        opacity: 0.2,
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true //隐藏或显示
                },

            }
        ],
        series: [
            {
                name: 'Direct',
                type: 'bar',
                barWidth: '25%',
                data: metaDate,//[10, 52, 200, 334, 390, 330, 220],
                itemStyle: {              // 自定义样式
                    color: '#60F4D9'      // 设置为红色
                },
                barGap: '-100%',
            }
        ]

    };






    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef1.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    },[props?.currentSubsidiary])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef1}></div>
    );
}
