import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function HistogramChart(props) {
  const {xAxisData,legendData,seriesData} = props.data
  const chartRef4 = useRef()
  var color = ['#2D5BFB', '#44EBEA', '#43A9EB', '#D7D7D7', '#8CA3B6', '#A982FF', '#00BFA5'];
  var option = {
    color: ['#2D5BFB', '#44EBEA', '#43A9EB', '#D7D7D7', '#8CA3B6', '#A982FF', '#00BFA5'],
    tooltip: {
      //提示框组件
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          backgroundColor: "#6a7985",
        },
      },
      textStyle: {
        color: "#839AAA",
        fontFamily: 'df',
        fontSize: 12,
      },
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "0%",
      top: '26%',
      padding: "0 0 10 0",
      containLabel: true,
    },
    legend: {
      show: true,
      left: "right",
      data:legendData,// ["铜矿", "铅锌矿", "银矿", "铝矿", "铅精矿",],
      y: "5%",
      itemHeight: 8,
      right: 10,
      icon: 'circle',
      itemWidth: 14,
      // itemHeight: 10,
      textStyle: {
        fontSize: 12,
        color: '#839AAA',
        fontFamily: 'df'
      },
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: true, //坐标轴两边留白
        data: xAxisData,
        // [
        //   "防城港",
        //   "宁波港",
        //   "连云港",
        //   "黄埔港",
        //   "青岛港",
        // ],
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: 12,
            color: '#839AAA',
            fontFamily: 'df'
          }
        },
        axisTick: {
          //坐标轴刻度相关设置。
          show: false,
        },
        axisLine: {
          //坐标轴轴线相关设置
          lineStyle: {
            color: "#fff",
            opacity: 0.2,
          },
        },
        splitLine: {
          //坐标轴在 grid 区域中的分隔线。
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        splitNumber: 5,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: '#839AAA',
            fontSize: 12,
            fontFamily: 'df',
          }
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ["#fff"],
            opacity: 0.06,
          },
        },
      },
    ],
    series: seriesData,
    // [{
    //   name: '铜矿',
    //   data: [2010, 1005, 1340, 670, 1675,],
    //   type: 'bar',
    //   barWidth: '10%',
    //   itemStyle: {
    //     normal: {
    //       color: function (params) {
    //         return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
    //       }
    //     }
    //   },
    // }, {
    //   name: '铅锌矿',
    //   data: [1128, 564, 752, 376, 940,],
    //   type: 'bar',
    //   barWidth: '10%',
    //   itemStyle: {
    //     normal: {
    //       color: function (params) {
    //         return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
    //       }
    //     }
    //   },
    // }, {
    //   name: '银矿',
    //   data: [732, 366, 488, 244, 610,],
    //   type: 'bar',
    //   barWidth: '10%',
    //   itemStyle: {
    //     normal: {
    //       color: function (params) {
    //         // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
    //         return color[2] //color[params.dataIndex]; //返回对应索引位置的颜色值
    //       }
    //     }
    //   },
    // },



    // {
    //   name: '铝矿',
    //   data: [204, 102, 136, 68, 107.5,],
    //   type: 'bar',
    //   barWidth: '10%',
    //   itemStyle: {
    //     normal: {
    //       color: function (params) {
    //         // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
    //         return color[3] //color[params.dataIndex]; //返回对应索引位置的颜色值
    //       }
    //     }
    //   },
    // },
    // {
    //   name: '铅精矿',
    //   data: [129, 64.5, 86, 43, 107.5,],
    //   type: 'bar',
    //   barWidth: '10%',
    //   itemStyle: {
    //     normal: {
    //       color: function (params) {
    //         // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
    //         return color[4] //color[params.dataIndex]; //返回对应索引位置的颜色值
    //       }
    //     }
    //   },
    // },
    // ]

  };






  useEffect(() => {
    const chart = echarts.init(chartRef4.current)
    chart.setOption(option, true)

    setInterval(function () {
      //用setInterval做动画感觉有问题
      // draw();
    }, 100);
    // 组件卸载
    return () => {
      chart.dispose()
    }
  }, [props.currentSubsidiary])
  return (
    <div style={{ width: "100%", height: "100%" }} ref={chartRef4}></div>
  );
}
