import * as echarts from 'echarts';
import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { geoJson, geoCoordMap } from "@/components/MapJson/word"
import { Modal, message, Table, Space } from "antd"
// import ship from "@/assets/d_ship.png";
import ship from "@/assets/ship.svg";
import end_port from "@/assets/d_s.jpg";
import start_port from "@/assets/d_s.jpg";
import warehouse from '@/assets/d_c.jpg';
import { v4 as uuid } from "uuid"
import { getpositionList, gettrackInfo, getMonitorBind } from "@/api"
import "./index.scss"
import OnlineVeidoModal from "@/components/OnlineVedioModal"
import RenderShip from "./modal/renderShip"
import RenderStorage from "./modal/renderStorage"

let enum_storage_type = {
    warehouse: "warehouse",
    port_start: "port",
    port_transhipment: "port",
    port_dest: "port",//port_transhipment", "port_dest"
    ship: "ship",
  };

export default function Map({ rateZoom, typeTabs, currentSubsidiary, showVedioInfo }) {
    const [isModalOpen, setisModalOpen] = useState(false);
    const [showVedioModal, setshowVedioModal] = useState(false);
    const [vedioInfo, setvedioInfo] = useState({});
    const [screenW, setscreenW] = useState(window.innerWidth);
    const [screenH, setscreenH] = useState(window.innerHeight);
    const [monitorList, setmonitorList] = useState([]);
    const [markesList, setmarkesList] = useState([]);
    const [modalTableData, setmodalTableData] = useState([{ id: 1, key: 1, address: "连云港", age: "铅锌矿", num: 288690 }]);
    const [customlabel, setcustomlabel] = useState({
        normal: {
            distance: .1,
            position: 'top',
            fontSize: 8,
            padding: [6, 6, 6, 6],
            show: true,//是否显示地名
            formatter: function (p) {
                return `{a|${p.name}}`
            },
            rich: {
                a: {
                    fontSize: 6,
                    color: '#fff',
                    backgroundColor: '#000',
                }
            }
        },

    });
    const columns = [
        {
            title: '提单号',
            dataIndex: 'code',
            key: 'name1',

        },

        {
            title: '商品名称',
            dataIndex: "title",
            key: 'name2',
        },
        {
            title: '仓库/货位',
            dataIndex: "storage",
            key: 'name11',
        },
        {
            title: '数量(吨)',
            dataIndex: "num",
            key: 'name111',
        },
        {
            title: '操作',
            key: 'name1111',
            render: (_, record) => (<span style={{ color: '#00E4E2', textDecoration: "underline" }} onClick={() => handleVedio(record)}>查看视频</span>)
        },

    ];
    const [serviesItem, setserviesItem] = useState({
        tooltip: {
            trigger: 'item',
            triggerOn: 'click',
            enterable: true,
            show: true,
            zlevel: 100,
            zIndex: 100,
            formatter: function (params, ticket, callback) {

                let m = params.value[2];
                (async () => {
                    let { data } = await gettrackInfo({ type: m?.type, traffic: m?.traffic });
                    callback(ticket, renderModal(data, m?.type, m?.title))

                })()
                let temp = `<div>加载中...... </div>`
                return temp;

            },

        },
        symbol: function (p) {

            let image = end_port
            if (p[2].type == 'port_start') {
                image = start_port
            }
            if (p[2].type == 'ship') {
                image = ship
            }
            if (p[2].type == 'warehouse') {
                image = warehouse
            }

            return `image://${image}`
        },
        symbolStyle: {

            opacity: 1,
        },
        symbolSize: 35 * rateZoom,
        label: {
            normal: {

                distance: .1,
                position: 'top',
                padding: [6, 6, 6, 6],
                show: true,//是否显示地名
                formatter: function (p) {
                    return `{a|${p.name}}`
                },
                rich: {
                    a: {
                        fontSize: 6,
                        color: '#fff',
                        backgroundColor: '#000',
                    }
                }
            },

        },
        itemStyle: {
            color: '#000', // 设置散点的背景色为红色，无透明度  
            borderColor: '#000000', // 设置散点的边框色为黑色  
            borderWidth: 122,// 设置散点的边框宽度为 1  
            opacity: 1,
        },
        name: 'light',
        type: 'scatter',
        coordinateSystem: 'geo',
        showEffectOn: 'render',
        data: []
    });
    const [option, _] = useState({

        color: [
            '#73b9bc',
            '#7289ab',
            '#91ca8c',
            '#f49f42'
        ],
        tooltip: {
            trigger: 'item',
            zindex: 1001,
            className: 'demo_custom_tooltip',
            alwaysShowContent: true,
            triggerOn: 'click',
            show: false,
            formatter: function (params) {

                if (typeof params.value[2] == 'undefined') {
                    return ''
                } else {
                    let temp = `<div>
            <h2>${params.value[3].code} </h2>
            ${params.value[3].title}
            </div>`
                    return temp;
                }
            },
        },

        geo: [
            {
                map: 'world',
                aspectScale: 0.66,
                layoutCenter: ['48.2%', '48.2%'], //地图位置
                layoutSize: '100%',
                roam: true,
                geoIndex: 0,
                itemStyle: {
                    borderColor: 'rgba(36,155,184,.1)',
                    borderWidth: 1,
                    shadowColor: "rgba(34, 202, 255, 0.1)",
                    shadowBlur: 10,
                    shadowOffsetX: 10,
                    shadowOffsetY: 0,
                    areaColor: {
                        type: 'radial',
                        x: screenW / 2,
                        y: screenH / 2,
                        r: 200,
                        colorStops: [{
                            offset: 0.1,
                            color: '#01BDA9' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#085068' // 50% 处的颜色
                        }],
                        global: true // 缺省为 false
                    },
                    emphasis: {
                        disabled: true,
                    }
                },
                emphasis: {
                    disabled: true
                }
            },
            // {
            //     map: 'world',
            //     aspectScale: 0.76,
            //     layoutCenter: ['50%', '48%'], //地图位置
            //     layoutSize: '90%',

            //     roam: "none",
            //     zoom: 1,
            //     geoIndex: 1,
            //     itemStyle: {
            //         borderColor: 'rgba(36,155,184,1)',
            //         borderWidth: 1,

            //         areaColor: {
            //             type: 'radial',
            //             x: screenW / 2,
            //             y: screenH / 2,
            //             r: 200,
            //             colorStops: [{
            //                 offset: 0.1,
            //                 color: '#01BDA9' // 0% 处的颜色
            //             }, {
            //                 offset: 1,
            //                 color: '#007BA2' // 50% 处的颜色
            //             }],
            //             global: true // 缺省为 false
            //         },
            //         emphasis: {
            //             disabled: true,
            //         }
            //     },
            //     emphasis: {
            //         disabled: true
            //     }
            // }
        ],
        series: [
            {
                type: 'effectScatter',
                coordinateSystem: 'geo',

                z: 0,
                rippleEffect: { //涟漪特效
                    period: 15, //动画时间，值越小速度越快
                    brushType: 'stroke', //波纹绘制方式 stroke, fill
                    scale: 7 //波纹圆环最大限制，值越大波纹越大
                },

                symbol: 'circle',
                symbolSize: function (val) {
                    return 12; //圆环大小
                },
                itemStyle: {
                    normal: {
                        show: true,
                        color: 'yellow' // 字体颜色
                        // color: '#0081E7' // 字体颜色
                    }
                },
                data: []
            },
        ],

    })
    let timer = useRef(null);
    useEffect(() => {
        getMarkers()

    }, [typeTabs, currentSubsidiary])
    // useEffect(() => {

    //     console.log('showVedioInfo', showVedioInfo, markesList);
    //     let currenPort = markesList?.find(f => f?.title?.includes(showVedioInfo));
    //     if (currenPort?.code) {
    //         handleVedio(currenPort?.code);
    //     }
    // }, [showVedioInfo])
    const getMarkers = async () => {
        const domEchart=document.getElementById('demo_echarts');
        
        const myChart = echarts.init(domEchart);
        // EventMyChart(domEchart)
        isTouchDevice();
        echarts.registerMap('world', geoJson);
        option.geo[0].zoom = typeTabs == "ck" ? 1 : .8;
        if (option.geo.length > 1) {

            option.geo[1].zoom = typeTabs == "ck" ? 1 : .8;
        }
        myChart.setOption(option)
        const iconZoom = typeTabs == "ck" ? 1 : 0.95;
        myChart.on("click", function (params) {
            // myChart.setOption(option); // 更新图表配置  
            // if (params.componentType == "geo") {
         
            // 隐藏提示框  
            renderModal([]);
            myChart.dispatchAction({
                type: 'hideTip'
            });
            // }
        })
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
            getMarkers()
        }, 1000 * 60 * 60)
        try {
            renderModal([]);
            console.log("currentSubsidiary", currentSubsidiary);
            const { data } = await getpositionList({ "pageNum": 1, "pageSize": 200 }, currentSubsidiary);
            let list = data?.list || []

            setmarkesList(list);
            if (list.length > 0) {
                let servies = [{
                    type: 'effectScatter',
                    coordinateSystem: 'geo',

                    z: 0,
                    rippleEffect: { //涟漪特效
                        period: 15, //动画时间，值越小速度越快
                        brushType: 'stroke', //波纹绘制方式 stroke, fill
                        scale: 7 //波纹圆环最大限制，值越大波纹越大
                    },

                    symbol: 'circle',
                    symbolSize: function (val) {
                        return 12; //圆环大小
                    },
                    itemStyle: {
                        normal: {
                            show: true,
                            color: 'yellow' // 字体颜色
                            // color: '#0081E7' // 字体颜色
                        }
                    },
                    data: []
                }]
                let markers = list.filter(f => f.type == "ship").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                // 货源地 port_start 船 ship 国内港口 port_dest 
                servies[0].data = markers
                let shi_data = list.filter(m => m?.type == "ship").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                let ship_labe = { ...customlabel, normal: { ...customlabel.normal } }
                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom, label: ship_labe, data: shi_data })

                let start_data = list.filter(m => m?.type == "port_start").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));
                let start_label = { ...customlabel, normal: { ...customlabel.normal, position: 'bottom' } }
                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom * iconZoom, label: start_label, data: start_data })

                let end_data = list.filter(m => ["port_transhipment", "port_dest", "warehouse"].includes(m?.type)).map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                servies.unshift({ ...serviesItem, symbolSize: 40 * rateZoom * iconZoom, label: { ...customlabel }, data: end_data })

                option.series = servies
                myChart.setOption(option)
                // debugger

            } else {
                // option.series[0].data = []
                // myChart.setOption(option)
            }
        } catch (error) {
            console.log('api-getpositionList', error);
        }


    }
    function isTouchDevice() {
        return ('ontouchstart' in window) || 
               (navigator.maxTouchPoints > 0) ||
               (navigator.msMaxTouchPoints > 0);
    }
    
    // 使用该函数
    if (isTouchDevice()) {
        console.log('当前浏览器运行环境支持触摸事件，可能具有触摸板或触摸屏');
    } else {
       console.log('当前浏览器运行环境不支持触摸事件，或不具有触摸板或触摸屏');
    }
const EventMyChart=(edom)=>{
    edom.addEventListener("touchmove",function(e){
     e.preventDefault(); // 阻止默认行为  
        console.log('e',e?.targetTouches);
    },{passive:false})
}
    // 实时监控 = 215419000
    const handleVedio = async (m) => {
        console.log(m, 'mm==');
        const { data: { monitorInfoBindDTOList = [], pic } } = await getMonitorBind(m?.code, '');
        // 是否有绑定的相机
        if (!monitorInfoBindDTOList || monitorInfoBindDTOList?.length == 0) {
            return message.info("暂无监控视频");
        }
        if (monitorInfoBindDTOList?.length > 0) {
            setshowVedioModal(true)
            setvedioInfo({
                warehouse: m?.warehouse,
                vid: uuid(),
                pic: pic?.url,
                deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
                deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId
            })
        }
        try { } catch (error) {
            console.log(data, "  ==--getMonitorBind--============")
        }



    }

    const showDetail = (info) => {
        console.log('info==', info);
        setisModalOpen(true);
        setmonitorList(info);
    }
    const renderModal = (billList, type, title) => {

        setTimeout(() => {
            const container = document.getElementById('tool-tip');
            if (container && container instanceof HTMLElement) {
                let root = ''
                if (container) {
                    root = ReactDOM?.createRoot?.(
                        document.getElementById('tool-tip')
                    );
                }


                if (root) {
                    // 在 Tooltip 中渲染 React 组件  
                    console.log(type, '====billList', billList);

                    root.render((() => {
                        if (billList?.length == 1) {
                            if (type == "ship") {
                                return <RenderShip props={billList[0]} />
                            }
                            //堆场
                            if (type !== "warehouse") {
                                return <RenderStorage
                                    item={billList[0]}
                                    title={title}
                                    showDetail={() => showDetail(billList)}
                                    storageType={enum_storage_type[type]} />
                            }
                            // 起始港数据 暂定为house
                            return <RenderStorage storageType={enum_storage_type[type]} title={title} item={billList[0]} showDetail={() => showDetail(billList)} />
                        }
                        if (billList?.length > 1) {

                            return <RenderStorage storageType={enum_storage_type[type]} title={title} billList={billList} showDetail={() => showDetail(billList)} handleVedio={() => handleVedio(billList)} />
                        }
                        return <div></div>



                    })());


                }
            } else {
                console.log("Target container is not a DOM element.");
            }
        }, 0);

        // 返回一个空的 div 作为 Tooltip 的占位符  
        return '<div id="tool-tip"></div>';

    }



    return (
        <>
            <div id="demo_echarts" key="0" style={{ width: screenW, marginTop: typeTabs == "ck" ? 0 : -80 }}></div>
            {/* {typeTabs == "ck" ? <div id="demo_echarts" key="0" style={{ width: screenW}}></div>
                : <div id="demo_echarts" key="1" style={{ width: 800*screenW/1920}}></div>} */}

            <Modal
                zIndex="99999990"
                title={<div className='demo_detail_title'> <span>{monitorList[0]?.warehouse || monitorList[0]?.title}</span></div>}
                className="demo_detail_modal"
                open={isModalOpen}
                mask={false}
                closable={true}
                footer={null}
                onCancel={() => setisModalOpen(false)}
                width={800}
                style={{ right: 0, top: 140 }}
                bodyStyle={{
                    background: '#062D39',
                }}
            >

                <Table
                    className="demo_modal_table "
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={monitorList.map(m => ({ id: m?.code, key: m?.code, code: m?.code, title: m?.title, storage: m?.endPort, num: m?.number ,warehouse:m?.warehouse}))} />

            </Modal>
            {/* 实时监控 */}
            {showVedioModal && <OnlineVeidoModal
                {...vedioInfo}
            />}
        </>
    )
}