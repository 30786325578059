import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function inventoryStatus() {

    const chartRef9 = useRef()
    var color = ['#0CD2E6', '#3751E6', '#FFC722', '#886EFF', '#008DEC', '#114C90', '#00BFA5'];

    var option = {
        legend: {       //对图形的解释部分
            // orient: 'vertical',
            data: ['在途', '库存', '委托加工'],
            top: 0,                                  //调整图例位置
            itemHeight: 6,
            right: 10,
            // y: 'center',
            icon: 'circle',
            textStyle: {                            //图例文字的样式
                color: '#839AAA',               //图例文字颜色
                fontSize: 12,                   //图例文字大小
                fontFamily: 'df'
            }

        },
        tooltip: {},
        grid: {
            top: '19%',
            left: '0%',
            right: '4%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: ['铜矿', '铅锌矿', '银矿', '铜矿砂及精矿','铅精矿',],
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: 12,
                    color: '#839AAA',
                    fontFamily: 'df'
                }
            }
        },
        yAxis: {
            axisLabel: {
                formatter: '{value}',
                textStyle: {
                    color: '#839AAA',
                    fontSize: 12,
                    fontFamily: 'df',
                }
            },
            splitLine: { //网格线

                lineStyle: {
                    color: '#313E42',
                    fontFamily: 'df',
                    // opacity: 0.2,
                    type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                },
                show: true //隐藏或显示
            },
        },
        series: [{
            name: '在途',
            data: [6700, 3760, 2440, 3820, 4220,],
            type: 'bar',
            barWidth: '14%',
            itemStyle: {
                normal: {
                    color: function (params) {
                        return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
                    }
                }
            },
        }, {
            name: '库存',
            data: [690, 1480, 590, 260, 900, ],
            type: 'bar',
            barWidth: '14%',
            itemStyle: {
                normal: {
                    color: function (params) {
                        return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
                    }
                }
            },
        }, {
            name: '委托加工',
            data: [3230, 1340, 500, 1350, 580, ],
            type: 'bar',
            barWidth: '14%',
            itemStyle: {
                normal: {
                    color: function (params) {
                        return color[2] //color[params.dataIndex]; //返回对应索引位置的颜色值
                    }
                }
            },
        }]
    };







    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef9.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)
        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef9}></div>
    );
}
