import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import "@/index.scss";
import { getMonthList } from "@/utils/common";
const months = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];
export default function situation({ unitType, typeTabs, data, sData = [] }) {
  let arr = [];
  sData?.map((item, index) => {
    return arr.push(item?.transportMode);
  });

  const chartRef8 = useRef();
  var color = ["#A982FF", "#60F4D9", "#3F96FF", "#F39422"];

  var option = {
    tooltip: {
    //   triggerOn: "click",
      trigger: "axis",
      showContent: true,
      axisPointer: {
        type: "",
      },
      formatter: function (params) {
        
        let relVal = params[0].name;
        for (let i = 0, l = params.length; i < l; i++) {
          relVal +=
            "<br/>" +
            params[i].marker +
            " " +
            params[i].seriesName +
            " : " +
            params[i].data +
            "车";
          // 在这里可以修改'次'为其他单位，根据数据实际情况来定
        }
        return relVal;
      },
      textStyle: {
        align: "left",
      },
    },
    legend: {
      data: arr, //data?.OverviewOne.xAxisData,
      itemHeight: 6, //修改icon图形大小
      itemWidth: 12,
      right: 10,
      textStyle: {
        //图例文字的样式
        color: function (params) {
          return color[params.dataIndex];
        }, //图例文字颜色
        fontSize: 12, //图例文字大小
        fontFamily: "df",
      },
    },
    grid: {
      top: "10%",
      left: "2%",
      right: "4%",
      bottom: "8%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月"], //getMonthList,
      axisLabel: {
        show: true,
        textStyle: {
          fontSize: 12,
          color: "#839AAA",
          fontFamily: "df",
        },
      },
    },
    yAxis: {
      type: "value",
      splitLine: {
        //网格线

        lineStyle: {
          color: "#313E42",
          // opacity: 0.2,
          type: "dashed", //设置网格线类型 dotted：虚线 solid:实线
        },
        show: true, //隐藏或显示
      },
      axisLabel: {
        formatter: "{value}",
        textStyle: {
          color: "#839AAA",
          fontSize: 12,
          fontFamily: "df",
        },
      },
    },
    series: [
      {
        name: sData?.[0]?.transportMode,
        type: "line",
        data: sData?.[0]?.data,
      },
      {
        name: sData?.[1]?.transportMode,
        type: "line",
        data: sData?.[1]?.data, // 出口
      },
      {
        name: sData?.[2]?.transportMode,
        type: "line",
        data: sData?.[2]?.data,
      },
      {
        name: sData?.[3]?.transportMode,
        type: "line",
        data: sData?.[3]?.data,
      },
    ],
  };

  useEffect(() => {
    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    const chart = echarts.init(chartRef8.current);


    // 设置图表实例的配置项和数据
    option.tooltip.formatter = function (params) {
       
      let relVal = params[0].name;
      for (let i = 0, l = params.length; i < l; i++) {
        relVal +=
          "<br/>" +
          params[i].marker +
          " " +
          params[i].seriesName +
          " : " +
          params[i].data +
          unitType;
        // 在这里可以修改'次'为其他单位，根据数据实际情况来定
      }
      return relVal;
    };

    option.series = sData.map((item, index) => {
      return {
        name: item?.transportMode,
        type: "line",
        data: item?.data,
      };
    });
     
      chart.setOption(option);
     // 监听点击事件  
     const handleWindowClick = (event) => {  
        // 点击窗口时，检查点击位置是否在图表区域内  
        chart.dispatchAction({
            type: "hideTip",
          });     
     
      }; 
      window.addEventListener('click', handleWindowClick,true);
  
    setInterval(function () {
      //用setInterval做动画感觉有问题
      // draw();
    }, 100);
    // 组件卸载
    return () => {

      window.removeEventListener('click', handleWindowClick); 
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      chart.dispose();
    };
  }, [sData, typeTabs]);

  return <div style={{ width: "100%", height: "100%" }} ref={chartRef8}></div>;
}
