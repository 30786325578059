import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { tooltip } from 'leaflet';

export default function HistogramChart(props) {
    const { xAxisData, metaDate } = {
        xAxisData: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
        ],

        metaDate: [6992393.75, 10412865.44, 12852709.54, 9395200.18, 18632225.36]
    }
    const chartRef1 = useRef()

    var option = {
        tooltip: {
            trigger: 'axis',
            confine: true ,// 或者 '#main' 如果你的图表容器有一个 ID  
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {       //对图形的解释部分
            // orient: 'vertical',
            data: ['采购额'],
            top: 0,                                  //调整图例位置
            itemHeight: 6,
            right: 10,
            // y: 'center',
            icon: 'circle',
            textStyle: {                            //图例文字的样式
                color: '#839AAA',               //图例文字颜色
                fontSize: 12,                   //图例文字大小
                fontFamily: 'df'
            }

        },
        grid: {
            top: '15%',
            left: '0%',
            right: '1%',
            bottom: '12%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: xAxisData,//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                axisTick: {
                    alignWithLabel: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                axisLabel: {
                    formatter: function (value) {
                        return value/10000 ; // 在这里添加你想要的单位，例如 'kg'、'm' 等
                    }
                },
                splitLine: { //网格线

                    lineStyle: {
                        color: '#313E42',
                        opacity: 0.2,
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                    show: true //隐藏或显示
                },

            }
        ],
        series: [

            {
                name: '采购额',
                type: 'bar',
                label: {
                    show: false,
                    position: 'top',
                    formatter: '{c}元'
                },
                barWidth: '25%',
                data: metaDate,//[10, 52, 200, 334, 390, 330, 220],
                itemStyle: {              // 自定义样式
                    color: '#60F4D9'      // 设置为红色
                },
                barGap: '-100%',
            }
        ]

    };






    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef1.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [props?.currentSubsidiary])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef1}></div>
    );
}
