  var list = [
    {name: "中铝物流集团黑龙江东轻有限公司", num:3296, numUnit:"车", weight:237024.349, weightUnit:"吨"},
    {name: "山金瑞鹏贸易有限公司", num:1278, numUnit:"车", weight:86611, weightUnit:"吨"},
    {name: "内蒙古都成矿业股份有限公司", num:610, numUnit:"车", weight:41823.620, weightUnit:"吨"},
    {name: "赤峰国源对外经贸有限公司", num:216, numUnit:"车", weight:15921.51, weightUnit:"吨"},
    {name: "陕西软微贸易有限责任公司", num:128, numUnit:"车", weight:8598, weightUnit:"吨"},
    {name: "巴彦淖尔紫金有色金属有限公司", num:78, numUnit:"车", weight:5304.71, weightUnit:"吨"},
    {name: "厦门建发浆纸集团有限公司", num:4426, numUnit:"车", weight:157621.618, weightUnit:"吨"},
    {name: "陆港供应链管理(哈尔滨)有限公司", num:842, numUnit:"车", weight:546, weightUnit:"吨"},
    {name: "广州市晨辉纸业有限公司", num:1030, numUnit:"车", weight:38964.597, weightUnit:"吨"},
    {name: "上海汇鸿浆纸有限公司", num:1065, numUnit:"车", weight:38448.977, weightUnit:"吨"},
    {name: "浙江恒优贸易发展有限公司", num:1272, numUnit:"车", weight:35675.755, weightUnit:"吨"},
    {name: "黑龙江三都国际贸易有限公司", num:1014, numUnit:"车", weight:26591.093, weightUnit:"吨"},
    {name: "通用国际贸易有限责任公司", num:439, numUnit:"车", weight:23054.577, weightUnit:"吨"},
    {name: "上海泰茹汇贸易有限公司", num:805, numUnit:"车", weight:20169.560, weightUnit:"吨"},
    {name: "厦门国贸纸业有限公司", num:206, numUnit:"车", weight:16563, weightUnit:"吨"},
    {name: "浙江万邦浆纸集团有限公司", num:127, numUnit:"车", weight:8245, weightUnit:"吨"},
    {name: "恒安(中国)纸业有限公司", num:304, numUnit:"车", weight:8012.852, weightUnit:"吨"},
    {name: "绥芬河市三都纸业有限责任公司", num:119, numUnit:"车", weight:728, weightUnit:"吨"},
    {name: "北京奥斐博进出口有限公司", num:75, numUnit:"车", weight:4900, weightUnit:"吨"},
    {name: "绥芬河市埃佩克进出口有限公司", num:71, numUnit:"车", weight:4640, weightUnit:"吨"},
    {name: "哈尔滨市众木联国际贸易有限公司", num:113, numUnit:"车", weight:2809.317, weightUnit:"吨"},
    {name: "哈尔滨亿阳纸业有限公司", num:73, numUnit:"车", weight:1916.043, weightUnit:"吨"},
    {name: "黑龙江霖森国际贸易有限公司", num:78, numUnit:"车", weight:1902.798, weightUnit:"吨"},
    {name: "浙江森林纸业有限公司", num:62, numUnit:"车", weight:1536.726, weightUnit:"吨"},
    {name: "浙江杭实善成实业有限公司", num:41, numUnit:"车", weight:1035.673, weightUnit:"吨"},
    {name: "青岛新锦华盛国际贸易有限公司", num:40, numUnit:"车", weight:992.643, weightUnit:"吨"},
    {name: "哈尔滨隆腾新材料科技有限公司", num:983, numUnit:"车", weight:63971.320, weightUnit:"吨"},
    {name: "大连泽凯商贸有限公司", num:397, numUnit:"车", weight:23808.945, weightUnit:"吨"},
    {name: "润德行(大连)供应链管理有限公司", num:135, numUnit:"车", weight:8186.679, weightUnit:"吨"},
    {name: "满洲里钰森经贸有限公司", num:8, numUnit:"车", weight:457, weightUnit:"吨"},
    {name: "新疆卓越国际物流有限公司", num:2, numUnit:"车", weight:115, weightUnit:"吨"}
]
export var shipperOutport = [...list.sort((a,b)=>b.num-a.num)];

export var shipperInport = [...list.sort((a,b)=>a.num-b.num)];
 