import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function situation() {

    const chartRef8 = useRef()
    var color = ['#4BB8EA', '#3CFFDB', '#50BA58', '#FF832D', '#166CC9', '#A982FF',];

    var option = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['防城港', '宁波港', '连云港', '黄埔港', '青岛港',],
            itemHeight: 6, //修改icon图形大小
            itemWidth: 12,
            right: 10,
            textStyle: {                            //图例文字的样式
                color: function (params) {
                    console.log(params.dataIndex,"===--7778888--===")
                    return color[params.dataIndex]
                },          //图例文字颜色
                fontSize: 12,                   //图例文字大小
                fontFamily: 'df'
            },

            // textStyle: {
            //     fontSize: 10,
            //     color: function (params) {
            //         return color[params.dataIndex]
            //     },
            //     fontFamily: 'df'
            // },
        },
        grid: {
            top: '19%',
            left: '0%',
            right: '4%',
            bottom: '0%',
            containLabel: true
        },

        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['铜矿', '铅锌矿', '银矿', '铜矿砂及精矿', '铅精矿',],
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: 12,
                    color: '#839AAA',
                    fontFamily: 'df'
                }
            }
        },
        yAxis: {
            type: 'value',
            splitLine: { //网格线

                lineStyle: {
                    color: '#313E42',
                    // opacity: 0.2,
                    type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                },
                show: true //隐藏或显示
            },
            axisLabel: {
                formatter: '{value}',
                textStyle: {
                    color: '#839AAA',
                    fontSize: 12,
                    fontFamily: 'df',
                }
            },
        },
        series: [
            {
                name: '防城港',
                type: 'line',
                stack: 'Total',
                data: [2010, 1128, 732, 1146, 1266,],
                itemStyle: {
                    normal: {
                        color: "#2D5BFB",
                        lineStyle: {
                            color: "#2D5BFB"
                        },
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                                fontFamily: 'df'
                            },
                        },
                    }
                },
            },
            {
                name: '宁波港',
                type: 'line',
                stack: 'Total',
                data: [1005, 564, 366, 573, 633,],
                itemStyle: {
                    normal: {
                        color: "#44EBEA",
                        lineStyle: {
                            color: "#44EBEA"
                        },
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                                fontFamily: 'df'
                            },
                        },
                    }
                },
            },
            {
                name: '连云港',
                type: 'line',
                stack: 'Total',
                data: [1340, 752, 488, 764, 844,],
                itemStyle: {
                    normal: {
                        color: "#43A9EB",
                        lineStyle: {
                            color: "#43A9EB"
                        },
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                                fontFamily: 'df'
                            },
                        },
                    }
                },
            },
            {
                name: '黄埔港',
                type: 'line',
                stack: 'Total',
                data: [670, 376, 244, 382, 422,],
                itemStyle: {
                    normal: {
                        color: "#D7D7D7",
                        lineStyle: {
                            color: "#D7D7D7"
                        },
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                                fontFamily: 'df'
                            },
                        },
                    }
                },
            },
            {
                name: '青岛港',
                type: 'line',
                stack: 'Total',
                data: [1675, 940, 610, 955, 1055,],
                itemStyle: {
                    normal: {
                        color: "#8CA3B6",
                        lineStyle: {
                            color: "#8CA3B6"
                        },
                        label: {
                            show: true,
                            position: "top",
                            textStyle: {
                                fontSize: 10,
                                color: '#fff',
                                fontFamily: 'df'
                            },
                        },
                    }
                },
            },

        ]
    };






    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef8.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [])
    return (
        <div style={{ width: "100%", height: "100%" }} ref={chartRef8}></div>
    );
}
