

const addLayer_map = (map, billObj, ly_track) => {
    let count = 0;
    map.eachLayer((item) => {
        count++
        if (item.options.billCode) {
            if (ly_track.length === 0) {
                ly_track.push(item)
            } else {
                let find = ly_track.find((f) => f.options.billCode === item.options.billCode && f.options.lyType === item.options.lyType)
                if (!find) {
                    ly_track.push(item)
                }
            }
        }
    })
    console.log('bill', billObj)
    console.log('绘制的图层', ly_track)
}

const addLayer_marker = (ly, ly_marker) => {
    if (ly_marker.length === 0) {
        ly_marker.push(ly)
    } else {
        let find = ly_marker.find((f) => f.options.billCode === ly.options.billCode && f.options.lyType === ly.options.lyType)
        if (!find) {
            ly_marker.push(ly)
        }
    }
}

const addLayer_billTrak = (ly_track, ly_marker, billCode, map) => {

    let findList = ly_track.filter((f) => f.options.billCode === billCode)

    console.log('findList', findList)
    findList.map((findObj) => {
        findObj.addTo(map)
    })

    let findmarkerList = ly_marker.filter((f) => f.options.billCode === billCode)
    findmarkerList.map((f) => {
        f.addTo(map)
    })
}

const setEyeStatus = (billShips,billCode) => {
    billShips.map((bill) => {
        if (bill.billCode === billCode) {
            bill.eye = true
        }
    })
}


export { addLayer_map, addLayer_marker, addLayer_billTrak,setEyeStatus }