import React from 'react';
import './rightModule.scss';
import PieChart from '@/components/LargeScreenModule/pieChart'
import ColumnarChart from '@/components/LargeScreenModule/columnarChart'
//WordCloud
import WordCloud from '@/components/LargeScreenModule/wordCloud'
export default function rightModule({info,rateZoom}) {
  const {place,variety,port}=info

  return (
    <div className='rightModule'>
     <div className='rightModuleTop'>
        <span className='Overview'>货物来源地</span>
      </div>
      <div className='Origin'>
          <PieChart place={place} rateZoom={rateZoom} />
      </div>
      <div className='rightModuleTop'>
        <span className='Overview'>货物类型占比</span>
      </div>

      <div className='Origin'>
          <ColumnarChart variety={variety} rateZoom={rateZoom} />
      </div>
      <div className='rightModuleTop'>
        <span className='Overview' >到货港存量占比</span>
      </div>
      <div className='Origin'>
          <WordCloud port={port} rateZoom={rateZoom}  />
      </div> 
    </div>
  );
}
