import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import tranck from "@/assets/ck.svg"
export default function wordCloud({port,rateZoom}): JSX.Element  {
  const wordCloudRef = useRef()
  port.length>8?port.length=8:''
  let plantCap =port.sort((a,b)=>(a-b)).map(m=>({name:m?.title,value:m?.ratio||0}));
console.log(plantCap,'sum');
let rate=.65*rateZoom;
let rateFont=1*rateZoom;


 var datalist = [
    {
        offset: [40, 52],
        symbolSize: 106*rate,
        opacity: .75,
        size:10*rateFont
    },
    {
        offset: [85, 31], 
        symbolSize: 88*rate,
        opacity: .84,
        size:12*rateFont
    },
    {
        offset: [6, 70],
        symbolSize: 84*rate,
        opacity: .8,
        size:10*rateFont
    }, 
   

    {
        offset: [70, 78],
        symbolSize: 78*rate,
        opacity: .7,
        size:9*rateFont
    },
    {
        offset: [16, 14],
        symbolSize: 66*rate,
        opacity: .95,
        size:9*rateFont
    },
   
    {
        offset: [60, 12],
        symbolSize: 66*rate,
        opacity: .88,
        size:9*rateFont
    }, 
  
    {
        offset: [98, 80],
        symbolSize: 60*rate,
        opacity: .6,
        size:11*rateFont
    },
    {
        offset: [24, 92],
        symbolSize: 40*rate,
        opacity: .68,
        size:9*rateFont
    }, 
 ]

var datas = [];
for (var i = 0; i < plantCap.length; i++) {
    var item = plantCap[i];
    var itemToStyle = datalist[i];
    datas.push({
        name: item?.name + '\n' + item?.value+'%', 
        value: itemToStyle.offset,
        symbolSize: itemToStyle.symbolSize,
    
        label: {
            normal: {
                textStyle: {
                    color:'#fff',
                    fontSize: itemToStyle.size
                }
            }
        },
        itemStyle: {
            normal: {
                color: '#000817',
                opacity: itemToStyle.opacity,
                borderColor:"#30586d"
            }
        },
    })
}
let option = {
    grid: {
        show: false,
        top: 10,
        bottom: 10
    },
    xAxis: [{
        gridIndex: 0,
        type: 'value',
        show: false,
        min: 0,
        max: 100,
        nameLocation: 'middle',
        nameGap: 5
    }],
    yAxis: [{
        gridIndex: 0,
        min: 0,
        show: false,
        max: 100,
        nameLocation: 'middle',
        nameGap: 30
    }],
    series: [{
        type: 'scatter',
        // symbol: `image://${tranck}`,
        symbol: `circle`,
        symbolSize: 120,
        label: {
            normal: {
                show: true,
                formatter: '{b}',
                color: '#fff',
                textStyle: {
                    fontSize: '20'
                }
            },
        },
        itemStyle: {
            normal: {
                color: '#00acea'
            }
        },
        data: datas
    }]
};
  useEffect(() => {
    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    const chart = echarts.init(wordCloudRef.current)

    // 设置图表实例的配置项和数据
    chart.setOption(option)
    window.onresize = function() {  
        chart.resize();  
    };
    // 组件卸载
    return () => {
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      chart.dispose()
    }
  }, [])
  return (
    <div style={{ width: "100%", height: "100%" }} ref={wordCloudRef}></div>
  );
}
