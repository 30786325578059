import React, { useEffect ,useState} from 'react';
import LeftInventoryOverview from "./leftInventoryOverview"
// LeftTradeOverview
import LeftTradeOverview from "./leftTradeOverview"
import './leftModule.scss';
import { currentEnvirement } from "@/utils/common"
import { use } from 'echarts';
import { log } from 'console';
export default function leftModule({ info, typeTabs, setshowVedioInfo, currentSubsidiary, screenList }) {
  // ck:库存总览 my:贸易总量 gs: 切换公司
  const [data, setdata] = useState({
    my: {
      typeTabs:typeTabs,
      tradeVolume: ['2', ',', '6', '5', '3', ',', '6', '8', '3', ',', '5', '0', '7',],// 29333.45,//贸易额 2653683507
      tradeName:'总贸易额',
      inventoryQuantity: '23930',//库存数
      inventoryName:'总库存数(吨)',
      categoryTotal: {//品类总贸易额（万元）
        xAxisData: 
          [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          // "11月",
          // "12月",
        ],
        legendData: '',
        metaDate: [96771.741, 108003.888, 136843.636, 131999.035, 138811.873, 123932.288, 118868.783, 82863.447, 46076.381,]
      },
      operate: {//运营成本总览
        m2R2Data: [
          {
            value: 45.6,
            legendname: "港口费用",
            name: "港口费用",
            itemStyle: { color: "#2D5BFB", },
          },
          {
            value: 6.84,
            legendname: "堆存费",
            name: "堆存费",
            itemStyle: { color:"#D7D7D7", },
          },
          {
            value: 4.28,
            legendname: "货物检验费",
            name: "货物检验费",
            itemStyle: { color: "#44EBEA", },
          },
          {
            value: 114.97,
            legendname: "信用证费用",
            name: "信用证费用",
            itemStyle: { color: "#8CA3B6", },
          },
          {
            value: 12.56,
            legendname: "其他",
            name: "其他",
            itemStyle: { color: "#A982FF", },
          },
          {
            value: 530.78,
            legendname: "关税",
            name: "关税",
            itemStyle: { color: "#43A9EB", },
          },
        ]
      },
    },
    gs: {
      typeTabs:typeTabs,
      tradeVolume: ['2', ',', '6', '5', '3', ',', '6', '8', '3', ',', '5', '0', '7',],//'14,161.91 ',//贸易额['0', '1', '0', ',', '0', '2', '8', ',', '9', '1', '1', '.', '1', '1',]
      tradeName:'铅锌矿贸易额(万元)',
      inventoryQuantity: '6580',//库存数
      inventoryName:'铅锌矿库存数（吨）',
      categoryTotal: {//品类总贸易额（万元）
        xAxisData: screenList?.monthlyVolume?.months,
        //   [
        //   "1月",
        //   "2月",
        //   "3月",
        //   "4月",
        //   "5月",
        //   "6月",
        //   "7月",
        //   "8月",
        //   "9月",
        //   "10月",
        //   "11月",
        //   "12月",
        // ],
        legendData: '',
        metaDate: [524, 503, 804, 1230, 986, 1627, 1141, 1402, 1364, ]
      },
      operate: {//运营成本总览
        // m2R2Data: [
        //   {
        //     value: 45.6,
        //     legendname: "港口费用",
        //     name: "港口费用",
        //     itemStyle: { color: "#2D5BFB", },
        //   },
        //   {
        //     value: 6.84,
        //     legendname: "堆存费",
        //     name: "堆存费",
        //     itemStyle: { color: "#D7D7D7", },
        //   },
        //   {
        //     value: 4.28,
        //     legendname: "货物检验费",
        //     name: "货物检验费",
        //     itemStyle: { color: "#44EBEA", },
        //   },
        //   {
        //     value: 114.97,
        //     legendname: "信用证费用",
        //     name: "信用证费用",
        //     itemStyle: { color: "#8CA3B6", },
        //   },
        //   {
        //     value: 12.56,
        //     legendname: "其他",
        //     name: "其他",
        //     itemStyle: { color: "##A982FF", },
        //   },
        //   {
        //     value: 530.78,
        //     legendname: "关税",
        //     name: "关税",
        //     itemStyle: { color: "#43A9EB", },
        //   },
        // ]
        m2R2Data: [
          {
            value: 45.6,
            legendname: "港口费用",
            name: "港口费用",
            itemStyle: { color: "#2D5BFB", },
          },
          {
            value: 6.84,
            legendname: "堆存费",
            name: "堆存费",
            itemStyle: { color: "#D7D7D7", },
          },
          {
            value: 4.28,
            legendname: "货物检验费",
            name: "货物检验费",
            itemStyle: { color: "#44EBEA", },
          },
          {
            value: 114.97,
            legendname: "信用证费用",
            name: "信用证费用",
            itemStyle: { color: "#8CA3B6", },
          },
          {
            value: 12.56,
            legendname: "其他",
            name: "其他",
            itemStyle: { color: "#A982FF", },
          },
          {
            value: 530.78,
            legendname: "关税",
            name: "关税",
            itemStyle: { color: "#43A9EB", },
          },
        ]
      },
    }
  });
  useEffect(
    () => {
      
     
      
      console.error(typeTabs, '===');

    }, [currentSubsidiary,typeTabs])
   
  return (
    <div style={{ width: '100%' }}>
      {/* 库存总览 */} {/* 贸易总览 */}
      {typeTabs == "ck33" ? <LeftInventoryOverview key={typeTabs} /> : <LeftTradeOverview currentSubsidiary={currentSubsidiary} key={currentSubsidiary} typeTabs={typeTabs} data={typeTabs == "my" ? data.my : data.gs} screenList={screenList} />}
    </div>
  );
}
