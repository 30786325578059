import cookie from 'react-cookies'
// 封装ls存取token

const key = 'pc-key'

const setToken = (token) => {
  return window.localStorage.setItem(key, token)
}

//设置cookie，第三个参数的意思是所有页面都能用这个cookie
const setUserCookie = (userInfo) => {
  let millisecond = new Date().getTime()
  let expiresTime = new Date(millisecond + 60 * 1000 * 50)
  return cookie.save('userInfo', userInfo, {
    path: '/',
    expires: expiresTime
  })
}

//设置cookie，第三个参数的意思是所有页面都能用这个cookie
const setCookie_reg_token = (token) => {
  return cookie.save('reg_token', token, {
    path: '/'
  })
}

//加载cookie
const getUserCookie = () => {
  return cookie.load('userInfo')
}

//加载注册cookie token
const getCookie_reg_token = () => {
  return cookie.load('token')
}

//加载注册cookie userCode
const getCookie_reg_userCode = () => {
  return cookie.load('userCode')
}


//删除cookie
const removeUserCookie = (userInfo) => {
  return cookie.remove('userInfo')
}


const getToken = () => {
  return window.localStorage.getItem(key)
}


const getToken_UID = () => {
  return window.localStorage.getItem('uid')
}

const getCookieToken = (key) => {

  let token = null
  let res = getUserCookie()
  if (res) {
    token = JSON.parse(res).token
  }
  return token
}

const removeToken = () => {
  return window.localStorage.removeItem(key)
}

export {
  setToken,
  getToken,
  removeToken,
  setUserCookie,
  getUserCookie,
  getCookieToken,
  removeUserCookie,
  getCookie_reg_token,
  setCookie_reg_token,
  getToken_UID,
  getCookie_reg_userCode
}