import { useState, useEffect } from "react";
import { List,Space } from "antd";
import styles from "./index.module.scss"
import "./index.scss"

const SortList = ({ title,data,currentSubsidiary }) => {
    const [data1, setdata1] = useState(data);
    useEffect(() => {
        console.log(currentSubsidiary)
        if(currentSubsidiary?.label=="分公司A"){
            setdata1(data1.map(m=>({...m,num:m?.num/2})))
        }
        if(currentSubsidiary?.label=="分公司B"){
            setdata1(data1.map(m=>({...m,num:parseInt(m?.num/3)})))
        }
        if(currentSubsidiary?.label=="分公司C"){
            setdata1(data1.map(m=>({...m,num:parseInt(m?.num/1.5)})))
        }
      
      }, [currentSubsidiary])
    return <>
        <List
            header={<div className="list_header">{title}</div>}
            className='demo_List_container'
            split={false}
            dataSource={data1}
            renderItem={(item, index) => (
                <List.Item >
                    <div className={index<3?`demo_item${index} demo_item`:'demo_item'} >
                        <div className="item_left">
                            <i>{index + 1}</i>
                            <span> {item.name}</span>
                        </div>
                        <div>{item.num} {item?.unit}</div>
                    </div>
                </List.Item>
            )}
        />
    </>
}
export default SortList