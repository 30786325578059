import { useEffect, useState, useRef } from "react"
import { Space, Divider, Button, Select } from "antd";
import "./index.scss";
import styles from "./index.module.scss"
import moment from "moment"
import { getWeek, currentEnvirement } from "@/utils/common"
import Logo from "@/assets/dj_logo.png"
import { subsidiary } from "@/api"
export default function TopModule({ settypeTabs,setcurrentSubsidiary }) {
    const [currentTime, setcurrentTime] = useState('');
    const [currentWeek, setcurrentWeek] = useState('');
    const [showpage, setshowpage] = useState('my');
    const [subsidiaryList, setsubsidiaryList] = useState([]);
    const [selectedCompany, setselectedCompany] = useState(null);
    let timer = useRef(null)

    useEffect(() => {
        settypeTabs(showpage)
        if (showpage != 'gs') {
            setselectedCompany(null);
            setcurrentSubsidiary({})
        }
    }, [showpage])
    useEffect(() => {
        getTime()

    }, [])
    useEffect(() => {
        getSubsidiary()
    }, [])
    
    const getSubsidiary = async () => {
        try {
            const { data } = await subsidiary()
            setsubsidiaryList(data || []);
        } catch (error) {
            console.error('api-subsidiary');
        }
    }
    // 获取当前时间和周几
    const getTime = () => {
        let time = moment().format('YYYY-MM-DD HH:mm:ss');
        setcurrentTime(time)
        let week = getWeek(time);
        setcurrentWeek(week)
        // console.log(time,'time',week);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            getTime()
        }, 1000)
    }

    let translate = {};
    if (currentEnvirement() === "PC") {
        translate = { transform: 'translate(-25px,25px)' };
        if (window.screen.width > 1920) {
            translate = { transform: 'translate(-30px,30px)' };
        }
    }


    // 切换公司
    const changeCompany = (v, a) => {
        console.log(v, a);
        setshowpage('gs')
        setselectedCompany(a?.label);
        setcurrentSubsidiary(a)
    }
    return (
        <div className="mz_top_container">


            <Space size={[1, 1]} className="left_info1">
                <div className={styles['top_left']} >
                    <img className={styles['logo']} src={Logo} />
                </div>
                {/* <Divider type="vertical" style={{ backgroundColor: '#fff', height: 14 }} />
            <div className="title"> {'数据可视化大屏'}</div> */}

            </Space>
            <Space size={[1, 1]} className="maddle_btn">
                {/* <Button type="text" className={showpage == "my" ? 'btn_active' : "btn"} onClick={() => setshowpage("my")}>进口数据大屏 </Button>
                <Button type="text" className={showpage == "ck" ? 'btn_active' : "btn"} onClick={() => setshowpage("ck")}>出口数据大屏 </Button> */}

                {/* <Select
                    style={{ width: 180, paddingLeft: 15 }}
                    placeholder="请选择子公司"
                    value={selectedCompany}
                    allowClear={true}
                    showArrow={true}
                    className="demo_select_company"
                    options={subsidiaryList.map(m=>({value:m?.code,label:m?.subCompany}))}
                    onChange={changeCompany}
                    onFocus={() => setshowpage('gs')}
                /> */}


            </Space>
            <Space size={[1, 1]} className="right_info1">
                <span>{currentTime}</span>
                <span>{currentWeek}</span>
                {/* <Divider type="vertical" style={{ backgroundColor: '#fff', height: 14 }} /> */}
                <div className="temperature">
                    <iframe id="youriframeid" src='/weather.html' style={translate} className="weather_iframe"></iframe>
                </div>

            </Space>
        </div>
    )
}