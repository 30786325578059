import { Space } from "antd"
import moment from "moment"
import pi from "@/assets/p_icon.svg"
import "./commonModul.scss"
const RenderShip = ({ props }) => {
    return <div className="ship_container">
        <div className="ship_base_info">
            <h3> {props?.code}</h3>
            <div className="port_time_content">

                <Space size={[0, 0]} className="port">
                    <img src={pi} style={{ paddingRight: 7 }} />
                    <Space direction="vertical" size={[0, 0]}>
                        <span>{props?.startPort}</span>
                        <span>{props?.endPort}</span>
                    </Space>

                </Space>

                <Space direction="vertical" size={[0, 0]} className="time">
                    <span> ATA: {props?.eta && moment(props?.eta).format('YY-MM-DD')}</span>
                    <span>ATD: {props?.etd && moment(props?.etd).format('YY-MM-DD')}</span>
                </Space>

            </div>
        </div>
        <Space size={[10, 0]} wrap className="ship_tags">
            <span style={{ overflow: 'hidden' }}> {props?.title}</span>
            <span> {props?.number}{props?.itemUnit || "吨"}</span>
            <span> {props?.shipCompany}</span>
            <span> {props?.shipType}</span>
            {props?.warehouse && <span> {props?.warehouse}</span>}
            {props?.voyage && <span> {props?.voyage}</span>}
        </Space>
    </div>
}
export default RenderShip