import { message, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { OnlineVedioApi,burialPoint } from '@/api'
import { v4 as uuid } from 'uuid'
import OnlineVedio from '../OnlineVedio';
function OnlineVedioModal(props) {
  console.log()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isShowOnlineVedio, setOnlineVedio] = useState(false)
  const [accessToken, setAccessToken] = useState("")
  const [url, setUrl] = useState("")
  const [isModalOpenLook, setIsModalOpenLook] = useState(false)
  console.log(props.pic, "---------2222222------")
  const { deviceSerialId, deviceChannelNo, vid,warehouse="汇链" } = props
  const [id, setId] = useState(uuid())
  const [timer, setTimer] = useState(null)
const [openTime, setopenTime] = useState(null);
  let ChildRef_bank = React.createRef()

  useEffect(() => {
    setopenTime(new Date().getTime());
    getVedio()
    return () => {
      setOnlineVedio(false)
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [vid])

  // 获取可绑定list
  const getVedio = async () => {
    const p = {
      deviceChannelNo,
      deviceSerialId,
    }
    let { data: { accessToken, video } } = await OnlineVedioApi(p)
    console.log("=======data======", accessToken, video)
    if (!accessToken || !video) {
      message.warning("摄像头已离线");
      setIsModalVisible(false)
      return false;
    }
    setIsModalVisible(true)

    setAccessToken(accessToken)
    setUrl(video)
    setOnlineVedio(true)
    setId(uuid())
  }
  const look = () => {
    setIsModalOpenLook(true);
  }
  const handleOk = () => {
    setIsModalOpenLook(false)
  }
  const handleCancelLook = () => { setIsModalOpenLook(false); };

  const handleCancel = async() => {
    await burialPoint("驾驶舱", openTime, 0, {
      platform: "BI",
      pageName: "驾驶舱-监控",
      name: "cameraView",
      attr: {
        device: deviceSerialId,
        channel: deviceChannelNo,
        warehouse: warehouse || "",
      },
    });
    try {

      console.log(ChildRef_bank.current.stopVedio())
    } catch (error) {
      console.log('handlecancel', error);
    }

    const timerOne = setTimeout(() => {
      setOnlineVedio(false)
      setIsModalVisible(false)
    }, 300);
    setTimer(timerOne)
  }
  // destroyOnClose = { true}
  return (<div>
    <Modal
      open={isModalVisible}
      destroyOnClose={true}
      style={{ top: 20 }}
      width={1020}
      footer={false}
      closeIcon={<div style={{color:'#fff',padding:10,cursor:'pointer',marginTop:-10}}>X</div>}
     zIndex="99999999"
     bodyStyle={{
      background: '#062D39',
  }}
      onCancel={handleCancel}>
      <div style={{ marginTop: 15 }}>
        {isShowOnlineVedio &&
          <OnlineVedio
            onRef={ChildRef_bank}
            uuid={id}
            accessToken={accessToken}
            url={url}
          ></OnlineVedio>}
      </div>
      <div style={{ marginTop: '20px' }}>
        {props.pic && (
          <>
            <span> 货物参照图</span>
            <img
              src={props.pic}
              onClick={look}
              style={{
                height: "40px",
                width: "40",
                margin: "10px",
              }}
            ></img>
          </>
        )}
      </div>
    </Modal >
    {
      isModalOpenLook && (
        <Modal
          title=""
          zIndex="111111112"
          open={isModalOpenLook}
          onOk={handleOk}
          footer={null}
          width={1000}
          onCancel={handleCancelLook}
        >
          <img
            src={props.pic}
            style={{
              textAlign: 'center',
              height: "450px",
              width: "95%",
            }}
          ></img>
        </Modal>
      )
    }
  </div >)
}

export default OnlineVedioModal