import { Button, Drawer, Steps, Row, Col, message, Spin, Descriptions, Space, Divider } from "antd";
import {
  MenuUnfoldOutlined,
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import L, { marker } from "leaflet";
import "leaflet.motion/dist/leaflet.motion.min.js";
import { polylineDecorator } from "leaflet-polylinedecorator";
import boat from "@/assets/chuan.svg";
import guoIcon from "@/assets/zhuan.svg";
import circleIcon from "@/assets/circle_icon.svg";
import blue_boat from "@/assets/boat_blue.svg";
import startIcon from "@/assets/qi.svg";
import car_blue from "@/assets/overlooking-car-red.svg";
import endIcon from "@/assets/zhong.svg";
import cangkuIcon from "@/assets/cangchu.svg";
import eye from "@/assets/eye.svg";
import noeye from "@/assets/no_eye.svg";
// import arrow from "@/assets/arrow_long.svg";
import arrow from "@/assets/arrowLeft.svg";
import orderIcon from "@/assets/tidan.svg";
import triangle1 from "@/assets/triangle1.svg";
import triangle2 from "@/assets/triangle2.svg";
// import orderIcon from "@/assets/order-icon.svg";
// worldMapImage  @/assets/supply-tools/taoqi.png
// import worldMapImage from "@/assets/worldMap.jpeg";
import linechat from "@/assets/line.png";
import { useEffect, useState, useLayoutEffect } from "react";
import { converToShipType, getTime } from "@/utils/common";
import {
  getstoreList,
  getPort,
  getShipTrack,
  getMonitorBind,
  loadbill,
  getprogressList,
  mainRecord,// 停靠港口信息
  gettrajectory,
} from "../../api";
import dateFormat from "dateformat";
import { v4 as uuid } from "uuid";
import { http } from "@/utils";
import {
  addLayer_map,
  addLayer_marker,
  addLayer_billTrak,
  setEyeStatus,
} from "./draw";
import { MovingMarker } from "@/utils/MovingMarker.js"
import { AnimatedMarker } from "@/utils/AnimatedMarker.js"
import "./index.scss";
import Modal from "antd/lib/modal/Modal";
import moment from "moment"
import OnlineVeidoModal from "@/components/OnlineVedioModal"
import StoreDrawer from "./mapComponents/storeDrawer.js";
import ProgressDrawer from "./mapComponents/progressDrawer.js";

const { Step } = Steps;
var map = null;

var LinghtGroup = null;

// 地图标注图层（轨迹起点和终点）
let markerGroup_SE = null;

// 地图标注图层（靠港）
let markerGroup_port = null;

// 地图标注图层 (轨迹)
let lineGroup_line = null;

// 地图标注图层 (圆圈)
let circleGroup_circle = null;

// 地图标注图层（动画）
let movingGroup_boat = null;

// 闪烁标注图层（动画）
let markLinghtGroup = null;

// 添加轨迹图层
let ly_track = [];

// marker 图层
let ly_marker = [];

// 提单船信息
let billShips = [];
let pathname = window.location.href.split("=")[1]
let id = pathname?.split('&')?.[0];
const offsetDrawer = 464;
const Track = (props) => {
  // 提单标记
  const { order } = props;
  const [mapIns, setmapIns] = useState(null);
  const [visible, setVisible] = useState(true);
  const [shipInfo, setShipInfo] = useState({});
  const [portInfo, setportInfo] = useState([]);
  const [isExpand, setExpend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [mapData, setmapData] = useState(null);
  const [mBill, setMbill] = useState();
  const [bill, setBill] = useState([]);
  // const [worldId, setWorld] = useState(1);
  const [worldType, setWorldType] = useState("vec");
  const [worldFontType, setWorldFontType] = useState("cva");
  const [tipVO, settipVO] = useState({})
  const [isHiddenHead, setisHiddenHead] = useState(false)
  const [showDrawer, setshowDrawer] = useState(true)

  const [drawerFreshkey, setdrawerFreshkey] = useState(uuid());
  const [showVedioModal, setshowVedioModal] = useState(false);
  const [showprogress, setshowprogress] = useState(false);
  const [showstore, setshowstore] = useState(false);
  const [vedioInfo, setvedioInfo] = useState({});
  const [progressInfo, setprogressInfo] = useState([]);
  const [storeInfo, setstoreInfo] = useState({});
  const [computeProgressWidth, setcomputeProgressWidth] = useState(1);

  let computeClientWidth = (storeLists = 0) => {
    let getOffsetWidth = document.body.clientWidth - offsetDrawer;
    if (storeLists == 0) {


      setcomputeProgressWidth(getOffsetWidth - 232);

    } else {
      setcomputeProgressWidth(getOffsetWidth - 394);
    }

    console.log('compute', (document.body.clientWidth - offsetDrawer - 200));
  }
  useEffect(() => {

    document.title = "物流轨迹"
    initMap();
    // map_orders();
    getPortInfo()
    setisHiddenHead(sessionStorage.getItem('unheard') == 'true')
    if (sessionStorage.getItem('hidebtn') != 'true') {
      openProgressAndStoreDrawer()
    }
  }, []);
  const openProgressAndStoreDrawer = async () => {
    const ladingCode = sessionStorage.getItem("code");

    const { billCode = '', orderCode = '' } = { billCode: '', orderCode: '' };

    const { data: progresData } = await getprogressList({ ladingCode, billCode, orderCode })
    const { data } = await getstoreList({ ladingCode });//: 'TD202307201' 
    setdrawerFreshkey(uuid())
    setshowprogress(true);
    setprogressInfo(progresData)
    setstoreInfo(data);
    setshowstore(true)
    computeClientWidth(data?.applyList?.length)
    try { } catch (error) {

    }
  }
  // 实时监控
  const handleVedio = async (m) => {
    try {
      const { data: { monitorInfoBindDTOList = [] } } = await getMonitorBind(m);
      // 是否有绑定的相机
      if (monitorInfoBindDTOList?.length == 0) {
        return message.info("暂无监控视频");
      }
      if (monitorInfoBindDTOList?.length > 0) {
        setshowVedioModal(true)
        setvedioInfo({
          vid: uuid(),
          pic: m?.ladingMonitorInfo?.pic?.url,
          deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
          deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId
        })
      }
    } catch (error) {
      console.log(data, "  ==--await getMonitorBind--============")
    }
  }
  // 初始化地图
  const initMap = () => {
    const map_options = {
      attributionControl: false,
      minZoom: 1,
      maxZomm: 10,
      zoomControl: false,
      //  crs: L.CRS.EPSG4326, // 天地图设置
    };

    map = L.map("map", map_options);
    map.setView([0, 180], 3);
    let zoomControl = L.control.zoom({ position: "topright" });
    map.addControl(zoomControl);
    // const mapkey = "7b84c7681c437faefd8ada162fb4845e"; // 7b84c7681c437faefd8ada162fb4845e,6665ee9e5b6e8b7778f3fc0f4a1e9913
    const mapkey = "13aaaf7907b41c2e04034d430da1ce36";
    setmapIns(map);
    const tdt_img = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/${worldType}_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=${worldType}&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const tdt_cia = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/${worldFontType}_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=${worldFontType}&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${mapkey}`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );

    const img_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=7b84c7681c437faefd8ada162fb4845e`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cva_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=7b84c7681c437faefd8ada162fb4845e`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const ter_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/ter_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=7b84c7681c437faefd8ada162fb4845e`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const cta_w = L.tileLayer(
      `https://t{s}.tianditu.gov.cn/cta_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cta&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=7b84c7681c437faefd8ada162fb4845e`,
      {
        subdomains: [0, 1, 2, 3, 4, 5, 6, 7],
        transparent: true,
        //zoomOffset:1,
        zIndex: 3,
      }
    );
    const d1 = L.layerGroup([tdt_img, tdt_cia]);
    const d2 = L.layerGroup([img_w, cva_w]);
    const d3 = L.layerGroup([ter_w, cta_w]);
    const baseLayers = {
      地图: d1,
      影像: d2,
      地形: d3,
    };
    map.addLayer(d1);
    L.control.layers(baseLayers, null).addTo(map);
    setmapData(map);
    map.addEventListener("click", onlatWithlong);
    // debugger
    shipTrackData(id)
  };
  // 靠港信息
  const getPortInfo = async (m) => {
    let now = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    try {
      const { data: { list: portData, tipVO } } = await mainRecord({ code: sessionStorage.getItem('code') });
      settipVO(tipVO)
      if (portData.length > 0) {
        let arrPorts = [];
        portData.map((m) => {
          // 港口 优先中文其次英文

          m.portName = m?.title

          // 国家
          m.countryName = m.countryCn || m?.contryEn;
          // 到港时间

          m.ata = m?.inTime ? moment(m?.inTime).format("YYYY-MM-DD") : "";
          // 离港时间

          m.atd = m?.outTime ? moment(m?.outTime).format("YYYY-MM-DD") : '';
          // 换船信息
          if (m.recordType === "TRANSHIPMENT_PORT") {
            m.nextShipNameEn = m.nextShipNameEn;
          }

          arrPorts.push(m);
          setCurrent(arrPorts.length - 1);
        });
        // debugger
        if (arrPorts.length > 0) {
          setportInfo(arrPorts);
        } else {
          setportInfo([]);
        }
      }
    } catch (error) {

    }

  };
  // 船舶轨迹
  const shipTrackData = async (m) => {

    // 绘制轨迹
    let lineList = [];
    setLoading(true);

    const { data: { list: arrLineList } } = await gettrajectory({ code: sessionStorage.getItem('code') });
    let coordinates = []
    if (arrLineList) {
      if (arrLineList && arrLineList.length > 0) {
        let lineLength = arrLineList.length;
        let lines = [];

        let isThrow180 = arrLineList?.find(f => Math.ceil(f?.lon / 10000 / 60) < -178);

        coordinates = arrLineList.map((m) => {
          let l_lat = m.lat / 10000 / 60;
          let l_lon = m.lon / 10000 / 60;
          // console.log('lon---==', isThrow180);
          if (l_lon < 0 && isThrow180) {
            l_lon += 360
          }

          lines.push([l_lat, l_lon]);

          if (m?.type === "port_start") {
            console.log('===-port_start----', l_lon);
            lineList.push(markerCritical(l_lat, l_lon, 'start', m));
            map.setView([l_lat, l_lon], 3);
          }
          if (m?.type === "port_dest") {
            lineList.push(markerCritical(l_lat, l_lon, 'dest', m));
          }
          if (m?.type === "port_transhipment") {
            lineList.push(markerCritical(l_lat, l_lon, 'transhipment', m));
          }
          if (m?.type === "warehouse") {
            lineList.push(markerCritical(l_lat, l_lon, 'warehouse', m));
          }
          return [l_lat, l_lon]
        });

        // 动态轨迹 
        // dynamicTrajector(coordinates);


        lineList.push(
          L.polyline(lines, {
            weight: 4,
            color: "blue",
            dashArray: '0',
            zIndexOffset: -1,

          })
        );

        // 添加轨迹箭头
        let Line = L.polyline(lines, {
          weight: 1,
          zIndexOffset: -1,
        });
        let arrowLine = L.polylineDecorator(Line, {
          zIndexOffset: -1,
          billCode: id,
          lyType: "arrow",
          patterns: [
            {
              offset: 60,
              repeat: 65,
              symbol: L.Symbol.arrowHead({
                pixelSize: 10,
                zIndexOffset: -1,
                pathOptions: {
                  zIndexOffset: -1,
                  fillOpacity: 2,
                  color: "blue",
                  weight: 0,
                },
              }),
            },
          ],
        });
        //  有轨迹的时候 执行画线和箭头
        if (arrLineList.find(f => f?.type == "ship")) {
          lineList.push(arrowLine);
        }
        console.log('arrowLine', arrowLine);
        // debugger
        //如果最后一个点是 ship marker-->ship
        let icon_boat = L.icon({
          iconUrl: boat,
          iconSize: [48, 48],
          iconAnchor: [18, 18],
          className: "boat", //边框
        });
        if (arrLineList[lineLength - 1]?.type === 'ship') {
          // debugger
          const shipMarker = L.marker(lines[lineLength - 1], {
            icon: icon_boat,
            rotationAngle: (arrLineList[lineLength - 1]?.cog / 100), // 旋转角度
            zIndexOffset: 9999,
          })

          lineList.push(shipMarker);
        }
        lineGroup_line = L.layerGroup(lineList).addTo(map);

        setLoading(false);
        // 添加圆点 
        cirMarker(lines, 10, arrLineList, m);
      } else {
        setLoading(false);
        message.error("当前提单暂无历史轨迹数据");
        setportInfo([]);
      }
    } else {
      setLoading(false);
    }

    try { } catch (error) {
      setLoading(false);
    }

  };
  // 动态轨迹
  const dynamicTrajector = (coordinates) => {
    map.fitBounds(coordinates);
    let icon_boat = L.icon({
      iconUrl: blue_boat,
      iconSize: [38, 38],
      iconAnchor: [18, 18],
      className: "boat", //边框
    });
    L.Marker.movingMarker(
      coordinates,
      5000, { autostart: true, loop: true, icon: icon_boat, }).addTo(map);
    L.polyline(coordinates,
      {
        weight: 4,
        color: "blue",
      }).addTo(map);

  }
  // "中转，起点，终点，仓库标记"
  const markerCritical = (l_lat, l_lon, type, m) => {
    let iconUrl = startIcon;
    let time = getTime(m?.posTime);
    let tips = `<div class="tips_container">
  <div class="tips_port">${m?.title}</div>`
    let content = `<div class="tips_content">离港: ${time}</div>`
    if (type === 'transhipment') {
      iconUrl = guoIcon;

      content = `<div class="tips_content">离港: ${time}</div>`
    }
    if (type === 'dest') {
      iconUrl = endIcon;

      content = `<div class="tips_content">到港： ${time}</div>`
    }
    if (type === 'warehouse') {
      iconUrl = cangkuIcon

      content = `<div class="tips_content">到仓： ${time}</div>`
    }
    tips += content;
    tips += '</div>'
    let icon = L.icon({
      iconUrl: iconUrl,
      iconSize: [36, 45],
      iconAnchor: [18, 40],
    });
    let marker = L.marker([l_lat, l_lon], {
      zIndexOffset: 2,
      icon: icon,
      billCode: id,
      lyType: "startPoint",
    })


    marker = L.marker([l_lat, l_lon], {
      zIndexOffset: 2,
      icon: icon,
      popupAnchor: [90, 90],
      billCode: id,
      lyType: "startPoint",
    }).bindPopup(tips, {
      className: "mypopup", //(i & 1) == 0 ? 'mypopup' : 'mypopup1',
      closeButton: false,
      autoClose: false,
      closeOnClick: false,
      closePopupOnClick: false,
      offset: [9, 9]
    })
      .on("mouseover", function () {
        this.openPopup();
      })
      .on("mouseout", function () {
        this.closePopup();
      });
    return marker
  }

  // 自定义地图轨迹圆点
  const cirMarker = (arr, len, data, m) => {

    let arrLen = arr.length,
      distance = arrLen <= len ? 1 : Math.round((arrLen / len) * 100) / 100,
      targetArr = [],
      coordinates = [];
    // debugger
    for (let i = 0; Math.round(i) < arrLen - 1; i += distance) {
      let num = Math.round(i);
      targetArr.push({
        utc: data[num].posTime,
        points: arr[num],
      });
      coordinates.push(arr[num])
    }


    //添加最后一个点
    targetArr.push({
      utc: data[arrLen - 1].posTime,
      points: arr[arrLen - 1],
    });

    let circleList = [];
    let point_circle = L.icon({
      iconUrl: circleIcon,
      iconSize: [14, 14],
    });

    targetArr.map((item, i) => {
      if (item.utc) {
        let circle = L.marker(item.points, {
          icon: point_circle,
          billCode: m,
          lyType: `circleMarker${i}`,
        });
        circle
          .bindPopup(`<span style="padding:4px 8px">${getTime(item.utc)}</span>`, {
            className: "mypopup1", //(i & 1) == 0 ? 'mypopup' : 'mypopup1',
            closeButton: false,
            autoClose: false,
            closeOnClick: false,
            closePopupOnClick: false,
          })
          .on("mouseover", function () {
            this.openPopup();
          })
          .on("mouseout", function () {
            this.closePopup();
          });
        addLayer_marker(circle, ly_marker);
        circleList.push(circle);
      }
    });
    circleGroup_circle = L.layerGroup(circleList).addTo(map);

    // 添加用于显示和隐藏的图层
    addLayer_map(map, m, ly_track);
  };


  // 地图点击
  const onlatWithlong = (e) => {
    console.log("location:", e.latlng.lat, e.latlng.lng);
  };

  const onClose = () => {
    console.log(map);
    setVisible(false);
  };
  const mBillModal = (mBill) => {
    setVisible(true);
    onShipClick(mBill, false);
  };


  //清除图层集合
  const clearGroup = () => {
    if (markerGroup_SE) {
      markerGroup_SE.clearLayers();
    }
    if (markerGroup_port) {
      markerGroup_port.clearLayers();
    }
    if (lineGroup_line) {
      lineGroup_line.clearLayers();
    }
    if (circleGroup_circle) {
      circleGroup_circle.clearLayers();
    }
    if (movingGroup_boat) {
      movingGroup_boat.clearLayers();
    }
  };

  // 模态框
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const computeRight = (info) => {
    if (!info) {
      return 232;
    }
    if (info?.applyList?.length == 0) {
      return 232;
    }
    return 394

  }

  return (
    <>
      {/* 地图 */}
      <div >
        <Spin spinning={loading}>
          <div id="map" className={isHiddenHead ? "mapstyle_track unhdead" : "mapstyle_track"}></div>
        </Spin>
      </div>

      {/* 地图sidebar */}
      <div>
        {showDrawer ? <Drawer
          className={isHiddenHead ? "map_drawer map_drawer_unhead" : "map_drawer"}

          closable={false}
          zIndex={9999}
          title={
            <div className="custom_drawer_title">  <Space className="drawer_title" align="center" size={10}>

              {/* <div className="ordericon_wrap">
                <img className="map_tip_arrow" src={orderIcon} />
              </div> */}
              <span>{tipVO?.shipType == '散货' ? tipVO?.shipName + " " + "  " + tipVO?.voyage : decodeURIComponent(id)}</span>
            </Space>
              <div onClick={() => setshowDrawer(false)} className="hide_drawer"> 收起 <img src={triangle2} /> </div>


            </div>

          }
          mask={false}
          maskClosable={false}
          placement="left"
          width={370}
          style={{ marginTop: "60px", marginLeft: 15 }}
          getContainer={false}
          onClose={onClose}
          open={visible}
        >
          <div className="custom_drawer">
            {/* 提单信息 */}
            {/* 散货 */}
            <Descriptions title={<div ><Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />提单信息</div>} >
              {tipVO?.shipType !== '散货' && <Descriptions.Item label="提单号" span="3">{tipVO?.code}</Descriptions.Item>}
              <Descriptions.Item label="形态" span="3">{tipVO?.shipType}</Descriptions.Item>
              <Descriptions.Item label="品名" span="3">{tipVO?.title}</Descriptions.Item>

              <Descriptions.Item label="仓库" span="2">{tipVO?.warehouse}</Descriptions.Item>
              <Descriptions.Item label="数量" >{tipVO?.number} 吨</Descriptions.Item>
              {tipVO?.shipType === '散货' && <Descriptions.Item span="2" label="船名">{tipVO?.shipName}</Descriptions.Item>}
              {tipVO?.shipType === '散货' && <Descriptions.Item label="船次" span="2" >{tipVO?.voyage}</Descriptions.Item>}
              <Descriptions.Item label="船公司" >{tipVO?.shipCompany}</Descriptions.Item>
            </Descriptions>
            <Divider dashed={true} />
            {/* 航次 */}
            <div className="drawer_sea">
              <Row>
                <Col span={11}>
                  <div className="font14">{tipVO?.startPort}</div>
                  {tipVO?.etd && <div className="font12">ETD: {moment(tipVO?.etd).format("YYYY-MM-DD")}</div>}
                </Col>
                <Col span={2}>
                  <img src={arrow} />
                </Col>
                <Col span={11} style={{ textAlign: "right" }}>
                  <div className="font14">{tipVO?.endPort}</div>
                  {tipVO?.eta && <div className="font12">ETA：{moment(tipVO?.eta).format("YYYY-MM-DD")}</div>}
                </Col>
              </Row>
            </div>

            {/* 靠港信息 */}
            <div style={{ paddingTop: "20px" }}>
              <Divider type="vertical" style={{ border: '1px solid #1C6CDC ' }} />
              <span className="leftSide_ship_title">停靠路径</span>
              {/* <span className="leftSide_expend" onClick={() => { clearLayer() }}>清除轨迹</span>
               */}
            </div>
            <div>
              <Steps progressDot
                current={current}
                className="custom_track_steps"
                // items={portInfo}
                direction="vertical">
                {portInfo.map((m, index) => (
                  <Step
                    key={uuid()}
                    title={
                      <div className="leftSide_port_title">
                        <span>
                          {m.recordType === `TRANSHIPMENT_PORT`
                            ? "[中转]" +
                            m.portName +
                            "  （" +
                            m?.nextShipNameEn +
                            "）" || ""
                            : m.portName}
                        </span>
                        <span
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          {m.countryName}
                        </span>
                      </div>
                    }
                    description={
                      <div
                        className={
                          "left_content"
                        }
                      >
                        {m.ata && (
                          <div className="leftSide_port_content end_port">
                            <div>
                              <span>[到港:]</span>
                              <span>{m.ata}</span>
                            </div>
                          </div>
                        )}
                        {m.atd && (
                          <div className="leftSide_port_content">
                            <div>
                              <span>[离港:]</span>
                              <span>{m.atd}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  />
                ))}
              </Steps>
            </div>
          </div>
        </Drawer> :
          <div className="show_drawer_btn" onClick={() => setshowDrawer(true)}>
            展开 <img src={triangle1} />
          </div>}
      </div>


      {/* 提单信息 */}
      <Modal
        maskClosable={false}
        title={null}
        width={347}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <div
            className="map_tip_top"
            style={{ cursor: "pointer" }}
            onClick={() => mBillModal(mBill)}
          >
            <span className="map_tip_no">
              <img className="map_tip_arrow" src={orderIcon} />
              {mBill?.billCode || ""}
            </span>
          </div>
          <div className="map_tip_sea2">
            <span className="map_tip_sea_left">{mBill?.loadPort || ""}</span>
            <span className="map_tip_arrowDiv">
              <img className="map_tip_arrow" src={arrow} />
            </span>
            <span className="map_tip_sea_right">
              {mBill?.unloadPort || ""}{" "}
            </span>
          </div>
          <div className="map_tip_bottom_time">
            <span className="map_tip_ata">
              ETD:&nbsp;{mBill?.gmtShip || ""}
            </span>
            <span className="map_tip_ata">
              ETA:&nbsp;{mBill?.gmtEta || ""}
            </span>
          </div>

          <div className="info_detail">
            {mBill?.order?.tags.map((m) => (
              <span className="order_item ">{m}</span>
            ))}
          </div>
        </div>
      </Modal>
      {/* 业务进度 */}
      {showprogress && <ProgressDrawer
        dataInfo={progressInfo}
        drawerFreshkey={drawerFreshkey}
        contentWrapperStyle={{ right: computeRight(storeInfo), width: computeProgressWidth, top: 'calc(100vh - 200px)' }}
        placement="bottom"
      />}
      {/* 库存监控 */}
      {showstore && <StoreDrawer
        storeInfo={storeInfo}
        handleVedio={handleVedio}
        drawerFreshkey={drawerFreshkey}
        contentWrapperStyle={{ top: 'calc(100vh - 200px)' }}
        placement="bottom"
      />}

      {/* 实时监控 */}
      {showVedioModal && <OnlineVeidoModal
        {...vedioInfo}
      />}
    </>
  );
};

export default Track;
