import { useState, useEffect } from "react"
import { Steps, Drawer, Space, Empty } from "antd"
import steps_progress from "@/assets/quan.svg";
import setps_wait from "@/assets/setps_wait.svg";
import close_btn from "@/assets/close_btn.svg";
import storeIcon from "@/assets/store.svg"
import empty_store from "@/assets/empty_store.jpg"
import moment from "moment"
import { getByTitle } from "@testing-library/react";
export default function StoreDrawer(props) {
  const { style, contentWrapperStyle, placement, drawerFreshkey, handleVedio, storeInfo } = props
  const [showstore, setshowstore] = useState(false);


  useEffect(() => {
    setshowstore(true)
  }, [drawerFreshkey])
  // 根据 状态 展示不同icon 库存监控
  const getStepsIconStore = (s) => {

    if (s === "finish") {
      return <img width='18' src={steps_progress} />;
    }
    return <img width='18' src={steps_progress} />;
  }
  const getStoreDrawerTitle = () => {
    if (storeInfo?.applyList?.length == 0) {
      return <span style={{ fontSize: 14, fontWeight: 500 }}> 库存监控</span>
    }
    return (
      <Space size={[8, 18]} style={{ fontSize: 12 }} align='center' className="custom_header">
        <span style={{ fontSize: 14, fontWeight: 500 }}> 库存监控</span>
        <span onClick={() => handleVedio(storeInfo?.ladingCode)} style={{ cursor: "pointer", color: '#155BD4' }}>
          <img width='16' src={storeIcon} />
          {'  '}
          实时监控
        </span>
        <span style={{ color: 'rgba(0,0,0,0.8)' }}>入库 <span style={{ color: '#D0021B' }}> {storeInfo?.input}</span></span>
        <span>/</span>
        <span style={{ color: 'rgba(0,0,0,0.8)' }}>出库 <span style={{ color: '#D0021B' }}> {storeInfo?.outPut}</span></span>
      </Space>
    )
  }
  // 提单状态
  const getStatus = (s, index) => {
    if (storeInfo?.applyList?.length - 1 == index) {
      return "waite";
    }
    if (s === "running") {
      return "process";
    }
    return "finish";
  };
  // 提单title
  const getTitle = (s) => {
    if (s === "input") {
      return <span style={{ color: '#0166FF' }}>入库</span>;
    }
    if (s === "waitoutput") {
      return <span style={{ color: 'rgba(0,0,0,0.4)' }}>提货</span>;
    }
    return <span style={{ color: '#0166FF' }}>提货</span>;
  };
  const getItem = (info) => {
    let lists = info?.applyList?.map((item, index) => (
      {
        title: getTitle(item?.kind),
        description: <span style={{ fontSize: 12, fontWeight: 400, lineHeight: "8px", color: 'rgba(0,0,0,0.4)' }}>{item?.gmtModify ? moment(item?.gmtModify).format("YYYY/MM/DD") : " "}</span>,
        icon: <div style={{ lineHeight: '21.5px', position: 'relative' }} className={index == storeInfo?.applyList?.length - 1 ? 'last_child' : ''}>
          <a className="icon_quantity"> {item?.itemQuantity || ''} 吨</a>
          <span className="steps_icon_wrap">

            {getStepsIconStore(item?.status)}
          </span>
        </div>,
        status: getStatus(item?.status, index),
      }
    )
    );
    if (info?.input - info?.outPut > 0) {
      lists.push({
        title: '提货',
        description: <span style={{ fontSize: 12, fontWeight: 400, lineHeight: "8px", color: 'rgba(0,0,0,0.4)' }}></span>,
        icon: <div style={{ lineHeight: '21.5px', position: 'relative' }} >

          <span className="steps_icon_wrap">

            <img width='18' src={setps_wait} />
          </span>
        </div>,
        status: 'waite',
      })
    }
    return lists;
  }
  return <Drawer
    open={showstore}
    // style={style}
    contentWrapperStyle={contentWrapperStyle}
    placement={'right'}
    closable={false}
    className={storeInfo?.applyList?.length > 0 ? "store_drawer" : "store_drawer drawer_empty store_drawer_empty"}
    title={getStoreDrawerTitle()}
    extra={<span onClick={() => setshowstore(false)}> <img src={close_btn} /></span>}
    // <CloseOutlined />
    mask={false}
    footer={null}
    bodyStyle={{ padding: 0 }}
  >
    <div className="store_drawer_content">


      {storeInfo?.applyList?.length > 0 ? <Steps
        className="custom_steps custom_steps_store"
        size="small"
        labelPlacement="vertical"
        items={getItem(storeInfo)}
      ></Steps> :
        <div className="empty_store" style={{ display: "flex", alignItems: 'center', flexDirection: 'column' }}>
          <img style={{ width: 64 }} src={empty_store} />
          <div>暂无库存展示</div>
        </div>
      }

    </div>
  </Drawer>
}