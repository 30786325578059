function Notfound () {
  const style = {
    textAlign: "center",
    paddingTop: "200px",
    color: "gray",
    fontSize: "18px"
  }
  return <div style={style}> 当前页面不存在 </div>
}

export default Notfound