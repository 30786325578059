import React, { useEffect } from 'react';
// InventoryStatus  TransitInventory
import TransitInventory from "./transitInventory"
import InventoryStatus from "./inventoryStatus"
import Situation from "./situation"
import './leftInventoryOverview.scss';
export default function leftInventoryOverview() {

    return (
        <div className='inventoryBox'>
            {/* 库存总览  inventoryOneImg*/}
            <div className='inventoryOneImg'></div>
            <div className='inventoryBoxTotal'>
                <div className='inventoryTotal'>库存总数（吨）</div>
                <div className='inventoryNum'>31860</div>
            </div>
            <div className='InTransitTree'>
                <div className='InTransitTreeOne'>
                    <div className='measure'>在途量</div>
                    <div className='measureNub'>20940</div>
                </div>
                <div className='InTransitTreeTwo'>
                    <div className='measure'>库存量</div>
                    <div className='measureNub'>3920</div>
                </div>
                <div className='InTransitTreeT'>
                    <div className='measure'>委托加工</div>
                    <div className='measureNub'>7000</div>
                </div>
            </div>

            <div>
                <div className='category'>货物库存详情</div>
                <div style={{ display: 'flex' }} className='categoryBorder'>
                    <div className='categoryBorderLeft'></div>
                    <div className='categoryBorderCenter'></div>
                    <div className='categoryBorderRight'></div>
                </div>
            </div>


            <div className='inventoryStatus'>
                <InventoryStatus />
                {/* <TransitInventory /> */}
            </div>

            <div>
                <div className='category'>港口/堆场库存情况</div>
                <div style={{ display: 'flex' }} className='categoryBorder'>
                    <div className='categoryBorderLeft'></div>
                    <div className='categoryBorderCenter'></div>
                    <div className='categoryBorderRight'></div>
                </div>
            </div>
            <div className='situation'>
                <Situation />
            </div>

        </div>
    );
}