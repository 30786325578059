const markList=[
    { code: 'WHSE48444',
        lat: 28842000,
        lon: 74052000,
        title: "阿荣旗",
        type: "video",
        typeMain: "warehouse",
        traffic:"WHSE48444"
    },
    { code: 'WHSE00444',
        lat: 29760000,
        lon: 70446000,
        title: "煜联货场",
        type: "video",
        typeMain: "warehouse",
        traffic:"WHSE00444"
    },
    { code: 'WHSE225022',
        lat: 17424000,
        lon: 71538000,
        title: "龙游东仓",
        type: "warehouse",
    
    }

]
export default markList