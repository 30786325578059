import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.min.css'
import App from './App';
import "./index.scss";
moment.locale('zh-cn')
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ConfigProvider locale={zh_CN}>
    <App />
  </ConfigProvider>
);


