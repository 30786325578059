import "./index.scss"
import { ScrollBoard } from "@/utils/@jiaminghi/data-view-react"

const SortList = ({configData,title }) => {
  const configList={
    data: configData?.sort((b,a)=>(a?.exportVolume||a?.importVolume)-(b?.exportVolume||b?.importVolume))?.map((m, i) => {
      return [`<div class="tj_item_container tj_demo_item${i} tj_demo_item">
          <div class="item_left">
              <i>${i + 1}</i>
              <span> ${m?.companyName}</span>
          </div>
          <div>${m?.exportVolume||m?.importVolume} </div>
      </div>`]
    }),
    hoverPause: false,
    evenRowBGC: "#f5f5f5",
    // oddRowBGC: "#fff",
    // rowHeight: '40',
    rowNum:4,
    waitTime:4000,
  };
  return <div className='tj_scrollBoard_container' >
    <div className="tj_list_header">{title||children}</div>
    <ScrollBoard config={configList} style={{ width: '100%',height: '100%' }} />
  </div>
}
export default SortList