import React, { useEffect ,useState} from 'react';
import LeftInventoryOverview from "./leftInventoryOverview"
// LeftTradeOverview
import LeftTradeOverview from "./leftTradeOverview"
import './leftModule.scss';
import { currentEnvirement } from "@/utils/common"
import { use } from 'echarts';
import { log } from 'console';
export default function leftModule({ info, typeTabs,setshowVedioInfo,currentSubsidiary }) {
  // ck:库存总览 my:贸易总量 gs: 切换公司
  const [data, setdata] = useState({
    my: {
      typeTabs:typeTabs,
      tradeVolume: ['0', '5', '9', ',', '8', '2', '5', ',', '2', '0', '8', '.', '7', '7',],// 29333.45,//贸易额
      tradeName:'2024年进口贸易额（元）',
      inventoryQuantity: '527',//库存数
      inventoryName:'年度到港数',
      categoryTotal: {//品类总贸易额（万元）
        xAxisData: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
          "1月",
          "2月",
        ],
        legendData: ["铁矿", "电解铜", "金矿", '银矿', '铅锌矿'],
        metaDate: [
          [365.792, 731.584, 1097.376, 12, 1097.376, 585.2672, 146.3168, 731.584, 365.792, 365.792, 731.584, 731.584],
          [708.0955, 1416.191, 2124.2865, 2, 2832.382, 1132.9528, 283.2382, 1416.191, 708.0955, 708.0955, 1416.191, 1416.191],
          [76.975, 153.95, 230.925, 3, 307.9, 123.16, 30.79, 153.95, 230, 76.975, 76.975, 153.95, 153.95],
          [217.189, 434.378, 651.567, 2, 868.756, 347.5024, 86.8756, 434.378, 217.189, 217.189, 434.378, 434.378],
          [98.621, 197.242, 295.863, 5, 394.484, 157.7936, 39.4484, 197.242, 98.621, 98.621, 197.242, 197.242],
        ]
      },
      operate: {//运营成本总览
        m2R2Data: [
          {
            value: 45.6,
            legendname: "港口费用",
            name: "港口费用",
            itemStyle: { color: "#2D5BFB", },
          },
          {
            value: 6.84,
            legendname: "堆存费",
            name: "堆存费",
            itemStyle: { color:"#D7D7D7", },
          },
          {
            value: 4.28,
            legendname: "货物检验费",
            name: "货物检验费",
            itemStyle: { color: "#44EBEA", },
          },
          {
            value: 114.97,
            legendname: "信用证费用",
            name: "信用证费用",
            itemStyle: { color: "#8CA3B6", },
          },
          {
            value: 12.56,
            legendname: "其他",
            name: "其他",
            itemStyle: { color: "#A982FF", },
          },
          {
            value: 530.78,
            legendname: "关税",
            name: "关税",
            itemStyle: { color: "#43A9EB", },
          },
        ]
      },
    },
    gs: {
      typeTabs:typeTabs,
      tradeVolume: ['0', '0', '0', ',', '0', '1', '4', ',', '1', '6', '1', '.', '9', '1',],//'14,161.91 ',//贸易额['0', '1', '0', ',', '0', '2', '8', ',', '9', '1', '1', '.', '1', '1',]
      tradeName:'铅锌矿贸易额(万元)',
      inventoryQuantity: '6580',//库存数
      inventoryName:'铅锌矿库存数（吨）',
      categoryTotal: {//品类总贸易额（万元）
        xAxisData: [
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
          "1月",
          "2月",
        ],
        legendData: '',
        metaDate: [708.10 , 1416.19 , 2124.29 , '', 2832.38 , 526.4, 283.24,1416.19 ,708.10 , 708.10 ,1416.19, 1416.19 ]
      },
      operate: {//运营成本总览
        m2R2Data: [
          {
            value: 45.6,
            legendname: "港口费用",
            name: "港口费用",
            itemStyle: { color: "#2D5BFB", },
          },
          {
            value: 6.84,
            legendname: "堆存费",
            name: "堆存费",
            itemStyle: { color: "#D7D7D7", },
          },
          {
            value: 4.28,
            legendname: "货物检验费",
            name: "货物检验费",
            itemStyle: { color: "#44EBEA", },
          },
          {
            value: 114.97,
            legendname: "信用证费用",
            name: "信用证费用",
            itemStyle: { color: "#8CA3B6", },
          },
          {
            value: 12.56,
            legendname: "其他",
            name: "其他",
            itemStyle: { color: "#A982FF", },
          },
          {
            value: 530.78,
            legendname: "关税",
            name: "关税",
            itemStyle: { color: "#43A9EB", },
          },
        ]
      },
    }
  });
  useEffect(
    () => {
      
      if(typeTabs=="gs"){

      
      if(currentSubsidiary?.label=="分公司A"){
        setdata({
          ...data, gs: {
            ...data.gs,
            inventoryQuantity:"889",
            tradeVolume: ['0', '0', '1', ',', '0', '9', '4', ',', '1', '6', '1', '.', '9', '1',]
          }
        })
      }
      if(currentSubsidiary?.label=="分公司B"){
        setdata({
          ...data, gs: {
            ...data.gs, inventoryQuantity:"1109",
            tradeVolume: ['0', '0', '0', ',', '0', '9', '4', ',', '1', '1', '1', '.', '1', '3',],
          }
        })
      }
      if(currentSubsidiary?.label=="分公司C"){
        setdata({
          ...data, gs: {
            ...data.gs, inventoryQuantity:"2009",
            tradeVolume: ['0', '0', '1', ',', '1', '9', '0', ',', '1', '1', '1', '.', '1', '3',]
          }
        })
      }
    }
     
      
      console.error(typeTabs, '===');

    }, [currentSubsidiary,typeTabs])
   
  return (
    <>
      {/* 库存总览 */} {/* 贸易总览 */}
      {typeTabs == "ck" ? <LeftInventoryOverview key={typeTabs} /> : <LeftTradeOverview currentSubsidiary={currentSubsidiary} key={currentSubsidiary} typeTabs={typeTabs} data={typeTabs=="my"?data.my:data.gs} />}
    </>
  );
}
