import { useState, useEffect } from "react"
import { Steps, Drawer } from "antd"
import steps_progress from "@/assets/quan.svg";
import close_btn from "@/assets/close_btn.svg";
import empty_store from "@/assets/empty_store.jpg"
import huigou from "@/assets/huigou.svg";
import langou from "@/assets/langou.svg";
import moment from "moment"
export default function ProgressDrawer(props) {
  const { contentWrapperStyle, placement, drawerFreshkey, dataInfo } = props
  const [showprogress, setshowprogress] = useState(false);

  useEffect(() => {
    setshowprogress(true)

  }, [drawerFreshkey])
  // 根据 状态 展示不同icon
  const getStepsIcon = (s) => {
    if (s === "finish") {
      return <img width='18' src={langou} style={{ marginTop: -2 }} />;
    }
    if (s === "running") {
      return <img width='18' src={steps_progress} style={{ marginTop: -2 }} />;
    }
    return <img width='18' src={huigou} style={{ marginTop: -2 }} />;
  }

  // 提单状态
  const getStatus = (s) => {
    if (s === "finish") {
      return "finish";
    }
    if (s === "running") {
      return "process";
    }
    return "wait";
  };
  const getDrawerTitle = (info) => {
    if (info?.shipType == "container") {
      return (
        <div>业务进度 {info?.orderCode} {info?.billCode && <span style={{ margin: "0 8px" }}>-</span>}<span style={{ color: 'rgba(0,0,0,0.6)' }}>{info?.billCode}</span></div>
      )
    }
    return (
      <div>业务进度 {info?.orderCode} {info?.shipFlight || info?.shipName && <span style={{ margin: "0 8px" }}>-</span>}<span style={{ color: 'rgba(0,0,0,0.6)' }}>{info?.shipName}{" "}{info?.shipFlight}</span></div>
    )

  }
  return <Drawer
    contentWrapperStyle={contentWrapperStyle}
    open={showprogress}
    className={dataInfo?.nodeList?.length > 0 ? "progress_drawer" : "progress_drawer drawer_empty "}
    placement={'right'}
    closable={false}
    title={getDrawerTitle(dataInfo)}
    extra={<span onClick={() => setshowprogress(false)}> <img src={close_btn} /></span>}
    mask={false}
  >

    {dataInfo?.nodeList?.length > 0 ? <Steps
      className="custom_steps"
      size="small"
      labelPlacement="vertical"
      items={dataInfo?.nodeList?.map((item) =>
        Object.assign(
          {},
          {
            title: item?.nodeName,
            description: <span style={{ fontSize: 12, fontWeight: 400, lineHeight: "8px", color: 'rgba(0,0,0,0.4)' }}>{item?.gmtCreate ? moment(item?.gmtCreate).format("YYYY/MM/DD") : " "}</span>,
            icon: <div style={{ lineHeight: '21.5px' }}> {getStepsIcon(item?.status)}</div>,
            status: getStatus(item?.status),
          }
        )
      )}
    ></Steps> :
      <div className="empty_store" style={{ display: "flex", alignItems: 'center', flexDirection: 'column' }}>
        <img style={{ width: 64 }} src={empty_store} />
        <div>暂无进度展示</div>
      </div>
    }
  </Drawer >
}