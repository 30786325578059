import React, { useEffect, useState } from 'react';
import riseDataImg from '@/assets/riseDataImg.png'
import InventoryDataImg from '@/assets/inventoryDataImg.png'
import HistogramChart from "./HistogramChart"
import CountUp from "../react-countUp/react-countup/count/index"
import LineDataChart from "./LineDataChart"
import PieDataChart from "./PieDataChart"
import './leftModule.scss';

export default function LeftTradeOverview(props) {
  const { tradeVolume, inventoryQuantity, categoryTotal, operate,currentSubsidiary, categoryTotalMoney, tradeName, inventoryName, } = props.data
  //  '0', '8', '9', '1','9', '1','9','1'
  const [data, setData] = useState(['0', '1', '0', ',', '0', '2', '8', ',', '9', '1', '1', '.', '1', '1',])
  const [height, setHeight] = useState(0);
  const run = () => {
    const random = Math.floor(Math.random() * (10000 - 1) + 1)

    const randomString = random.toString()
    const arr = []
    for (let i = 0, len = randomString.length; i < len; i += 1) {
      arr.push(randomString.charAt(i))
    }

    setData(arr)
  }


  useEffect(()=>{
    // console.log('===-typetabs==',props.typeTabs);
    
    },[props.typeTabs])

  return (
    <div className='leftData'>
      <div className='leftDataTitle'></div>
      <div className='tradeVolume'>{tradeName}</div>
      <div className='Trade'>
        {tradeVolume.map((item, index) => {
          if (item === ',') {
            return (
              <span className='comma' key={item+index} >
                {item}
              </span>
            );
          } else if (item === '.') {
            return (
              <span className='comma' key={item+index} >
                {item}
              </span>
            );
          } else {
            return (
              <CountUp
                start={0}
                key={item + '-' + index}
                end={Number(item)}
                className='TradeData'
                // delay={2}
                // duration='30000'
              />
            );
          }
        })}

      </div>


      <div className='inventoryData'>
        <div className='inventoryDataLeft'>
          <div className='inventoryDataLeftBorder'></div>
          <div className='inventoryDataConter'>
            <div className='inventoryDataConterOne'>{inventoryName}</div>
            <div className='inventoryDataConterTwo'>{inventoryQuantity}</div>
            <div className='inventoryDataConterThree' ><span className='monthData'>本月</span>
              <img src={riseDataImg} alt="" className='riseDataImg' />
              <span className='riseData'> 1847吨</span></div>
          </div>
        </div>
        <div className='InventoryDataBoxImg'>
          <img src={InventoryDataImg} alt="" className='InventoryDataImg' />
        </div>
      </div>
      <div>
        <div className='category'> 品类总贸易额（万元）</div>
        <div style={{ display: 'flex' }} className='categoryBorder'>
          <div className='categoryBorderLeft'></div>
          <div className='categoryBorderCenter'></div>
          <div className='categoryBorderRight'></div>
        </div>
      </div>
      <div className='HistogramChart'>
        {/* 折线图 */}
        {
          props.typeTabs == 'my' ?
           <LineDataChart key={props?.typeTabs} currentSubsidiary={props.currentSubsidiary}  typeTabs={props?.typeTabs} data={categoryTotal} /> 
           : 
           <HistogramChart key={props?.typeTabs} currentSubsidiary={props.currentSubsidiary} typeTabs={props?.typeTabs}   data={categoryTotal} />
        }
      </div>
      <div className='operate'></div>
      <div className='PieDataChart'>
        {/* <div className='PieDataChartLeft'> */}
        <PieDataChart {...props} data={operate}  />
        {/* </div> */}
        {/* <div className='PieDataChartRight'>


        </div> */}
      </div>

    </div>
  );
}