import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function LineDataChart(props) {
  const { xAxisData, legendData, metaDate } = {//品类总贸易额（万元）
    xAxisData: [
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
      "1月",
      "2月",
    ],
    legendData: ["铁矿", "电解铜", "金矿", '银矿', '铅锌矿'],
    metaDate: [
      [365.792, 731.584, 1097.376, 12, 1097.376, 585.2672, 146.3168, 731.584, 365.792, 365.792, 731.584, 731.584],
      [708.0955, 1416.191, 2124.2865, 2, 2832.382, 1132.9528, 283.2382, 1416.191, 708.0955, 708.0955, 1416.191, 1416.191],
      [76.975, 153.95, 230.925, 3, 307.9, 123.16, 30.79, 153.95, 230, 76.975, 76.975, 153.95, 153.95],
      [217.189, 434.378, 651.567, 2, 868.756, 347.5024, 86.8756, 434.378, 217.189, 217.189, 434.378, 434.378],
      [98.621, 197.242, 295.863, 5, 394.484, 157.7936, 39.4484, 197.242, 98.621, 98.621, 197.242, 197.242],
    ]
  }
  const chartRef6 = useRef()
  // var xAxisData = [

  //   "3月",
  //   "4月",
  //   "5月",
  //   "6月",
  //   "7月",
  //   "8月",
  //   "9月",
  //   "10月",
  //   "11月",
  //   "12月",
  //   "1月",
  //   "2月",
  // ];
  // var legendData = ["铁矿", "电解铜", "金矿", '银矿', '铅锌矿'];

  var serieData = [];
  // var metaDate = [
  //   [365.792, 731.584, 1097.376, 12, 1097.376, 585.2672, 146.3168, 731.584, 365.792, 365.792, 731.584, 731.584],
  //   [708.0955, 1416.191, 2124.2865, 2, 2832.382, 1132.9528, 283.2382, 1416.191, 708.0955, 708.0955, 1416.191, 1416.191],
  //   [76.975, 153.95, 230.925, 3, 307.9, 123.16, 30.79, 153.95, 230, 76.975,  76.975,153.95,153.95],
  //   [217.189, 434.378, 651.567, 2, 868.756, 347.5024, 86.8756, 434.378, 217.189, 217.189, 434.378, 434.378],
  //   [98.621, 197.242, 295.863, 5, 394.484, 157.7936, 39.4484, 197.242, 98.621, 98.621, 197.242, 197.242],
  // ];
  for (var v = 0; v < legendData.length; v++) {
    var serie = {
      name: legendData[v],
      type: "line",
      symbol: 'none',//"circle",
      symbolSize: 10,
      data: metaDate[v],
    };
    serieData.push(serie);
  }

  var colors = ["#2D5BFB",  "#44EBEA", "#8CA3B6", "#A982FF", "#43A9EB"]
  var option = {
    tooltip: {
      trigger: 'axis',
      confine: true ,// 或者 '#main' 如果你的图表容器有一个 ID  
      axisPointer: {
          type: 'cross'
      }
  },
    colors: ["#2D5BFB",  "#44EBEA", "#8CA3B6", "#A982FF", "#43A9EB"],
    title: {
      text: '',
      textAlign: "left",
      textStyle: { color: "#fff", fontSize: "16", fontWeight: "normal" },
    },
    legend: {
      show: true,
      left: "right",
      data: ['托', "包"],
      y: "5%",
      itemWidth: 12,
      itemHeight: 2,
      textStyle: {
        fontSize: 12,
        color: '#839AAA',
        fontFamily: 'df'
      },
    },
    color: colors,
    grid: {
      left: "0%",
      top: "19%",
      bottom: "9%",
      right: "0%",
      containLabel: true,
    },
    // tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
    xAxis: [
      {
        type: "category",
        axisLine: { show: true, lineStyle: { color: "#6173A3" } },
        axisLabel: { interval: 0,rotate: 45,  textStyle: { color: "#9ea7c4", fontSize: 12, fontFamily: 'df' } },
        axisTick: { show: false },
        data: ['青岛(恒顺丰)', '青州(恒晟)', '成都（捷瑞）', '佛山(恒晟)']
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 10000,
       
        // axisLabel: {
        //   formatter: function (value) {
        //     return value / 100; // 在这里添加你想要的单位，例如 'kg'、'm' 等
        //   }
        // },
        axisTick: { show: true },
        splitLine: { show: false },
        // axisLabel: { textStyle: { color: "#9ea7c4", fontSize: 12, fontFamily: 'df' } },
        axisLine: { show: true, lineStyle: { color: "#6173A3" } },
      },
      {
        type: 'value',
        min: 0,
        max: 50000,

        axisTick: { show: false },
        splitLine: { show: false },
        // axisLabel: { textStyle: { color: "#9ea7c4", fontSize: 12, fontFamily: 'df' } },
        axisLine: { show: true, lineStyle: { color: "#6173A3" } },
         position: 'right'
      },

    ],
    series: [
      {
        name: '托',
        type: 'line',
        data: [175, 301, 690, 9728],
        // data: [10200, 14015, 1015, 41815],

      },
      {
        name: '包',
        type: 'line',
        yAxisIndex: 1, // 使用第二个Y轴  
        data: [10200, 14015, 0, 41815],

      }
    ],

  };

  useEffect(() => {
    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    const chart = echarts.init(chartRef6.current)

    // 设置图表实例的配置项和数据
    chart.setOption(option, true)

    setInterval(function () {
      //用setInterval做动画感觉有问题
      // draw();
    }, 100);
    // 组件卸载
    return () => {
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      chart.dispose()
    }
  }, [props?.currentSubsidiary])
  return (
    <div style={{ width: "100%", height: "100%" }} ref={chartRef6}></div>
  );
}
