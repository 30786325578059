import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

export default function pieChart({place,rateZoom}) {

  const chartRef = useRef()
  var color = ['#0CD2E6', '#3751E6', '#FFC722', '#886EFF', '#008DEC', '#114C90', '#00BFA5'];
  var legend = place?.map(m=>m?.title);
  var seriesData = place?.map(m=>({name:m?.title,value:m?.ratio}));
 
  var option = {
    // backgroundColor: '#050e31',
    
    color: color,
    grid: {
      top: '15%',
      left: 50,
      right: '1%',
      bottom: 5,
      containLabel: true,
    },
    polar: {center: ['30%', '45%'],}, //环线
    angleAxis: {

    },
    radiusAxis: {
      min: 80,
      max: 160,
      interval: 20,
      axisLine: {
        show: false,
        lineStyle: {
          color: '#0B3E5E',
          width: 1,
          type: 'solid',
        },
      },
      axisLabel: {
        // 环线的数字
        formatter: '{value} %',
        show: false,
        padding: [0, 0, 2, 0],
        color: '#0B3E5E',
        fontSize: 8*rateZoom,
      },
      splitLine: {
        // 环线样式
        lineStyle: {
          color: 'rgba(255,255,255,.1)',
          width: 2,
          type: 'solid',
        },
      },
    },
    legend: {
      selectedMode:false,
      icon:'circle',
      // type:'scroll',
      orient: 'vertical',
      top: 'center',
      itemWidth: 10*rateZoom,   
  itemHeight: 10*rateZoom, 
      right: 5*rateZoom,
      textStyle: {
        align: 'left',
        verticalAlign: 'middle',
        rich: {
          name: {
            color: 'rgba(255,255,255,0.5)',
            fontSize: 10*rateZoom,
          },
          value: {
            color: 'rgba(255,255,255,0.5)',
            fontSize: 10*rateZoom,
          },
          rate: {
            color: 'rgba(255,255,255,0.9)',
            fontSize: 10*rateZoom,
          },
        },
      },
      data: legend,
      formatter: (name) => {
        if (seriesData.length) {
          const item = seriesData.filter((item) => item.name === name)[0];
          return `{name|${name}：} {rate| ${item.value}%}`;
        }
      },
    },
    series: [
      {
        name: '需求类型占比',
        type: 'pie',
        center: ['30%', '45%'],
        radius: ['70%', '80%'],
        label: {
          normal: {
            show: false,
            position: 'center',
            formatter: '{value|{c}%}'+'\n{label|{b}}',
            rich: {
              //中间文字
              value: {
                // fontWeight:'nomal',
                color:'#fff',
                align: 'center',
                verticalAlign: 'middle',
                fontSize: 16*rateZoom,
              },
              label: {
                align: 'center',
                color:'#fff',
                verticalAlign: 'middle',
                fontSize: 12*rateZoom,
              },
            },
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '12',
            },
          },
        },
        labelLine: {
          show: false,
          length: 0,
          length2: 0,
        },
        itemStyle: {//间隔
          normal: {
            borderWidth: 5,
            borderColor: '#050e31',
          },
        },
        data: seriesData,
      },
     
    ],
  };





  function getDefaultSelected(myChart) {
    let index = 0;
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: 0,
    });
    myChart.on('mouseover', (e) => {
      if (e.dataIndex !== index) {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: index,
        });
      }
    });
    myChart.on('mouseout', (e) => {
      index = e.dataIndex;
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: e.dataIndex,
      });
    });
  }

  useEffect(() => {
    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    const chart = echarts.init(chartRef.current)

    // 设置图表实例的配置项和数据
    chart.setOption(option, true)
    getDefaultSelected(chart);
    setInterval(function () {
      //用setInterval做动画感觉有问题
      // draw();
    }, 100);
    // 组件卸载
    return () => {
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      chart.dispose()
    }
  }, [])
  return (
    <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>
  );
}
