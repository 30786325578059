import { useEffect, useState, useRef } from "react"
import { Space, Divider } from "antd";
import "./index.scss";
import wendu from "@/assets/wendu.png"
import logo_jk from "@/assets/logo_jk.png"
import weather from "@/assets/weather.png"
import moment from "moment"
import { getWeek,currentEnvirement } from "@/utils/common"
 
export default function TopModule(props) {
    const [currentTime, setcurrentTime] = useState('');
    const [currentWeek, setcurrentWeek] = useState('');
    let timer = useRef(null)

    useEffect(() => {
        getTime()
    })
    // 获取当前时间和周几
    const getTime = () => {
        let time = moment().format('YYYY-MM-DD HH:mm:ss');
        setcurrentTime(time)
        let week = getWeek(time);
        setcurrentWeek(week)
        // console.log(time,'time',week);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            getTime()
        }, 1000)
    }
   
    let translate={};
    if(currentEnvirement()==="PC"){
        translate={transform:'translate(-25px,25px)'};
        if(window.screen.width>1920){
        translate={transform:'translate(-30px,30px)'};
        }
    }



    return (
        <div className="large_screen_top_container">
            {/* <div className="left_control_btn" style={{visibility:'hidden'}}> 数据总览</div> */}
            <div className="title"> {props?.title||localStorage.getItem('companyName')||'杭州汇链科技智慧供应链全景平台'}</div>
            
            <Space size={[1, 1]} className="right_info">
                <span>{currentTime}</span>
                <span>{currentWeek}</span>
                <Divider type="vertical" style={{ backgroundColor: '#fff', height: 14 }} />
                <div className="temperature"> 
                <iframe id="youriframeid" src='/weather.html' style={translate} className="weather_iframe"></iframe>
                </div>
        
            </Space>
        </div>
    )
}