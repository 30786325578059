import * as echarts from 'echarts';
import { useEffect, useState, useRef } from 'react';
import { geoJson, geoCoordMap } from "./word"
import chuan from '@/assets/chuan.svg'
import { Modal ,message} from "antd"
import ship from "@/assets/ship.svg";
import end_port from "@/assets/end_port.svg";
import start_port from '@/assets/start_port.svg';
import warehouse from '@/assets/warehouse.jpg';
import tooltip_start from "@/assets/tooltip_start.svg";
import camera_icon from "@/assets/camera_icon.svg";
import tooltip_end from "@/assets/tooltip_end.svg";
import moment from "moment"
import {v4 as uuid }from "uuid"
import { getpositionList, gettrackInfo,getMonitorBind } from "@/api"
import "./index.scss"
import OnlineVeidoModal from "@/components/OnlineVedioModal"
export default function Map({ rateZoom }) {
    const [isModalOpen, setisModalOpen] = useState(false);
    const [customTipInfo, setcustomTipInfo] = useState({});
    const [showVedioModal, setshowVedioModal] = useState(false);
    const [vedioInfo, setvedioInfo] = useState({});
    const [apiHeader, setapiHeader] = useState({});
    const [customlabel, setcustomlabel] = useState({
        normal: {
            distance: .1,
            position: 'top',
            fontSize: 8,
            padding: [6, 6, 6, 6],
            show: true,//是否显示地名
            formatter: function (p) {
                return `{a|${p.name}}`
            },
            rich: {
                a: {
                    fontSize: 6,
                    color: '#fff',
                    backgroundColor: '#000',
                }
            }
        },

    });
    const [serviesItem, setserviesItem] = useState({
        tooltip: {
            trigger: 'item',
            triggerOn: 'click',
            enterable: true,
            show: true,
            zlevel: 100,
            zIndex:100,
            formatter: function (params, ticket, callback) {

                let m = params.value[2];
                (async () => {
                    let { data } = await gettrackInfo({ type: m?.type, traffic: m?.traffic });
                    callback(ticket, renderModal(data))

                })()
                let temp = `<div>加载中...... </div>`
                return temp;

            },

        },
        symbol: function (p) {
            console.log(p[2].type);
            let image = end_port
            if (p[2].type == 'port_start') {
                image = start_port
            }
            if (p[2].type == 'ship') {
                image = ship
            }
            if (p[2].type == 'warehouse') {
                image = warehouse
            }

            return `image://${image}`
        },
        symbolSize: 35 * rateZoom,
        label: {
            normal: {

                distance: .1,
                position: 'top',
                padding: [6, 6, 6, 6],
                show: true,//是否显示地名
                formatter: function (p) {
                    return `{a|${p.name}}`
                },
                rich: {
                    a: {
                        fontSize: 6,
                        color: '#fff',
                        backgroundColor: '#000',
                    }
                }
            },

        },
        // itemStyle: {
        //     normal: {
        //         fontSize: 12,
        //         backgroundColor:'red'
        //     },
        // },
        name: 'light',
        type: 'scatter',
        coordinateSystem: 'geo',
        showEffectOn: 'render',
        data: []
    });
    const [option, _] = useState({

        color: [
            '#73b9bc',
            '#7289ab',
            '#91ca8c',
            '#f49f42'
        ],
        backgroundColor: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
                {
                    offset: 0,
                    color: 'transparent', // 0% 处的颜色
                },
                {
                    offset: 1,
                    color: 'transparent', // 100% 处的颜色
                },
            ],
            globalCoord: false, // 缺省为 false
        },
        title: {
            top: 10,
            text: '',
            subtext: '',
            x: 'center',
            textStyle: {

            },
        },
        tooltip: {
            trigger: 'item',

            className: 'custom_tooltip',
            alwaysShowContent: true,
            triggerOn: 'click',
            show: false,
            formatter: function (params) {
                console.log(params);
                if (typeof params.value[2] == 'undefined') {
                    return ''
                } else {
                    let temp = `<div>
            <h2>${params.value[3].code} </h2>
            ${params.value[3].title}
            </div>`
                    return temp;
                }
            },
        },
        legend: {
            orient: 'vertical',
            y: 'bottom',
            x: 'right',
            data: ['流量'],
            textStyle: {
                color: '#fff',
            },
        },
        geo: {
            map: 'world',
            aspectScale: 0.75,
            layoutCenter: ["46%", "50%"], //地图位置
            layoutSize: '100%',
            roam: "none",
            zoom: 1.3,
            geoIndex: 0,
            itemStyle: {
                normal: {
                    borderColor: 'rgba(147, 235, 248, 0.4)',
                    borderWidth: 0.8,
                    shadowColor: "#4674DF",
                    // shadowBlur: 20,
                    areaColor: {
                        type: 'linear-gradient',
                        x: 0,
                        y: 1200,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0,
                            // color: '#fff' // 0% 处的颜色
                            color: '#4674DF' // 0% 处的颜色
                        }, {
                            offset: 1,
                            // color: '#000' // 50% 处的颜色
                            color: '#7CACF1' // 50% 处的颜色
                        }],
                        global: true // 缺省为 false
                    },
                },
                emphasis: {
                    disabled: true,

                }
            },
            emphasis: {
                disabled: true
            }
        },
        series: [
            {
                type: 'effectScatter',
                coordinateSystem: 'geo',

                z: 0,
                rippleEffect: { //涟漪特效
                    period: 15, //动画时间，值越小速度越快
                    brushType: 'stroke', //波纹绘制方式 stroke, fill
                    scale: 7 //波纹圆环最大限制，值越大波纹越大
                },

                symbol: 'circle',
                symbolSize: function (val) {
                    return 12; //圆环大小
                },
                itemStyle: {
                    normal: {
                        show: true,
                        color: 'yellow' // 字体颜色
                        // color: '#0081E7' // 字体颜色
                    }
                },
                data: []
            },
            {
				type: 'lines',
				zlevel: 2,
				effect: {
					show: true,
					period: 4, //箭头指向速度，值越小速度越快
					trailLength: 0.02, //特效尾迹长度[0,1]值越大，尾迹越长重
					symbol: 'arrow', //箭头图标
					symbolSize: 5, //图标大小
				},
				lineStyle: {
					normal: {
                        color:'red',
						width: 1, //尾迹线条宽度
						opacity: 0, //尾迹线条透明度
						curveness: .3 //尾迹线条曲直度
					}
				},
				data: []
			},
        ],

    })
    let timer = useRef(null);
    useEffect(() => {
        getMarkers()



    }, [])
    const getMarkers = async () => {
        var myChart = echarts.init(document.getElementById('echarts'));
        echarts.registerMap('world', geoJson);
        myChart.setOption(option)
        myChart.on("click", function (params) {
            console.log('click-map');
            // Modal.confirm({
            //     content: 'Some descriptions',
            // })
            option.tooltip.alwaysShowContent = false; // 修改为 false  
            myChart.setOption(option); // 更新图表配置  
            if (params.componentType == "geo") {

                // myChart.setOption(option)
                // 隐藏提示框  
                myChart.dispatchAction({
                    type: 'hideTip'
                });
            }
        })
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
            getMarkers()
        }, 1000 * 60 * 60)
        try {
            const { data } = await getpositionList({ "pageNum": 1, "pageSize": 200 });
            let list = data?.list || []
            if (list.length > 0) {

                let markers = list.filter(f => f.type == "ship" && f?.traffic != '477232800').map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                // 货源地 port_start 船 ship 国内港口 port_dest 
                option.series[0].data = markers
                // option.series[1].data =markers.map(m=>([{coord:[m?.value[0],m?.value[1]],value:3000},{coord:[113.122,29.009]}])) ;

                let start_data = list.filter(m => m?.type == "port_start").map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));
                let start_label = { ...customlabel, normal: { ...customlabel.normal, position: 'bottom' } }
                option.series.unshift({ ...serviesItem, symbolSize: 15 * rateZoom, label: start_label, data: start_data })
                let shi_data = list.filter(m => m?.type == "ship" && m?.traffic != '477232800').map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                let ship_labe = { ...customlabel, normal: { ...customlabel.normal, padding: [0, 0, 0, 0] } }
                option.series.unshift({ ...serviesItem, symbolSize: 30 * rateZoom, label: ship_labe, data: shi_data })
                let end_data = list.filter(m => ["port_transhipment", "port_dest", "warehouse"].includes(m?.type)).map(m => ({ name: m?.title, value: [m?.lon / 600000, m?.lat / 600000, m] }));

                option.series.unshift({ ...serviesItem, symbolSize: 16 * rateZoom, label: { ...customlabel }, data: end_data })
                myChart.setOption(option)
                // debugger

            }
        } catch (error) {
            console.log('api-getpositionList', error);
        }


    }

    const itemModal = (customTip) => {
        console.log(customTip);
        setisModalOpen(true)
    }
    //  点击marker 渲染订单列表
    const readerBillList = (arr) => {
        let listContainer = "<div class='list_container'>"
        let items = "";
        arr.map(m => {
            let item = `<div class='item_container' onclick="handleMarker(m)" data-v=${m?.code} key=${m?.code}>`;
            // let icon = `<img src=${tidan} />`;
            let title = `<div class='item_code'>${m?.code}</div>`
            if (m?.shipType == '散货') {
                title = `<div> <span style="margin-right:6px">${m?.shipName || ""}</span><span>${m?.voyage || ""}</span></div>`
            }

            item += title
            item += "</div>"
            items += item
        })
        listContainer += items;
        listContainer += "</div>";
        return listContainer;
    }
 // 实时监控
 const handleVedio = async (m) => {

    const { data: { monitorInfoBindDTOList = [], pic } } = await getMonitorBind(m, '');
    // 是否有绑定的相机
    if (!monitorInfoBindDTOList || monitorInfoBindDTOList?.length == 0) {
        return message.info("暂无监控视频");
    }
    if (monitorInfoBindDTOList?.length > 0) {
        setshowVedioModal(true)
        setvedioInfo({
            vid: uuid(),
            pic: pic?.url,
            deviceChannelNo: monitorInfoBindDTOList[0]?.deviceChannelNo,
            deviceSerialId: monitorInfoBindDTOList[0]?.deviceSerialId
        })
    }
    try { } catch (error) {
        console.log(data, "  ==--getMonitorBind--============")
    }



}
    //  点击marker 渲染提单信息
    const renderMarkerTips = (m) => {

        const {
            code,
            shipType = "集装箱",
            shipName
        } = m

        let container = "<div class='marker_container'>";
        let header = "<div class='marker_header'>"
        let middle = "<div class='marker_middle'>"
        let footer = "<div class='marker_footer'>"


        header +=
            `<div class="header_title"><h2> ${m?.shipType == '散货' ? ((m?.shipName || "") + " " + "  " + (m?.voyage || "")) : m?.code
            } </h2> <span>${moment(m?.gmtCreate).format("YYYY-MM-DD") || " "}</span></div>

                <div class="header_info"> <span> ${shipType || ''}</span>
                 <span> ${m?.title || ''}</span>
              <span>${m?.number || ''}吨</span></div>`


        // <dl><dt>${shipType === '集装箱' ? '提单号' : '船名'}</dt><dd>${shipType === '集装箱' ? code : shipName}</dd></dl>
        middle +=
            `<div class="middle_item">
                 <div><img src=${tooltip_start} /> <span>${m?.startPort || ''}</span></div>
                <div class="middle_time"><span>ETD:</span>${m?.etd && moment(m?.etd).format("YYYY-MM-DD") || '-'} </div>
             </div>
             <div class="middle_item">
                 <div><img src=${tooltip_end} /> <span>${m?.endPort || ''}</span></div>
                 <div class="middle_time"> <span>ETA:</span>${m?.eta && moment(m?.eta).format("YYYY-MM-DD") || '-'}</div>
             </div>
         `
        footer += `<div id='camera_btn'><img src=${camera_icon} /> 实时监控</div>`
        header += "</div>"
        middle += "</div>"
        footer += "</div>"

        container += header
        container += middle
        container += footer
        container += "</div>"
        return container
    }

    const renderModal = (billList) => {
        var rootDom = document.createElement("div");

        if (!billList || billList?.length == 0) {
            rootDom.innerHTML = ' <p style="width:56px"> 暂无数据</p>'
        } else if (billList.length === 1) {
            rootDom.className = "modal_container"
            rootDom.innerHTML = renderMarkerTips(billList[0]);
            const domCamera = rootDom.querySelector('#camera_btn');
            domCamera.onclick = function () {
                handleVedio(billList[0]?.code)
            }
        } else {
            rootDom.innerHTML = readerBillList(billList);
            let domItem = rootDom.querySelectorAll('.item_container');
            domItem.forEach(f => {
                f.onclick = function () {
                    let billCode = f.getAttribute("data-v");
                    let customTip = billList.find(f => f.code == billCode);
                    setcustomTipInfo(customTip)
                    itemModal(customTip)
                    // handleVedio(customTip.code)
                    console.log(customTip);
                }
            })
        }

        return rootDom
    }

    return (
        <>

            <div id="echarts"></div>
            <Modal
                className="mark_modal"
                open={isModalOpen}
                mask={false}
                closable={false}
                footer={null}
                onCancel={() => setisModalOpen(false)}
                width={236}
                bodyStyle={{ padding: 0 ,marginTop:20}}
              
            // centered={true}
            >
                <div className="modal">
                    <div className="custom_tooltip" >
                        <div className="modal_container" dangerouslySetInnerHTML={{ __html: renderMarkerTips(customTipInfo) }}></div>
                        <div className="cover_footer_btn" onClick={() => handleVedio(customTipInfo?.code)}>
                        {/* <dl ><dt><img width='16' src={camera_icon} /></dt><dd> 实时监控</dd></dl> */}
                    </div>
                    </div>
                </div>
            </Modal>
             {/* 实时监控 */}
             {showVedioModal && <OnlineVeidoModal
                {...vedioInfo}
            />}
        </>
    )
}