import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
const colors = [
  "#534EE1",
  "#ECD64F",
  "#00E4F0",
  "#44D16D",
  "#124E91",
  "#BDC414",
  "#C8CCA5",
];
export default function columnarChart({
  rateZoom,
  selectedIndex,
  typeTabs,
  screenList,
}) {
  console.log(rateZoom, selectedIndex, typeTabs, "进口数据333");
  const chartRef = useRef();
  console.log(typeTabs, "typeTabs");

  const [options, setoptions] = useState({
    grid: {
      left: "2%",
      top: "14%",
      right: "0%",
      bottom: "25%",
    },

    color: colors,
    // tooltip: {
    //   position: "top",
    // },
    xAxis: {
      type: "category",
      data: [],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: true,
        rotate: 0,
        interval: 0,
        textStyle: {
          padding: [14, 0, 0, 0],
          fontSize: 14,
          color: "rgba(255,255,255,1)",
        },
      },
    },
    yAxis: {
      type: "value",

      axisLabel: {
        show: false,
        color: "#999999",
        fontSize: 12 * rateZoom,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "rgba(255, 255, 255, 0.20)",
          width: 1,
          type: "dashed",
        },
      },
    },
    series: [
      {
        type: "pictorialBar",
        name: "货物类型占比",

        itemStyle: {},
        //     {
        //         name: m,
        //         value: m,
        //         label: {
        //             show: true,
        //             position: "top",
        //             formatter: function ({value}) {
        //                 var str = "{a|" + value+ "}";
        //                 return str;
        //             },
        //             rich: {
        //                 a: {
        //                     fontSize: 12*rateZoom,
        //                     color: "#fff",
        //                     align: "center",
        //                     height: 20,
        //                 },
        //             },
        //         },

        //     }
        // )),
        data: [],
        stack: "a",
        symbol: "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
      },
    ],
  });

  useEffect(() => {
    console.log('useEffect=[]',screenList);
    
    let timer;
    const chart = echarts.init(chartRef.current);


    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    initEcharts();
  
    // echarts.init(chartRef.current).setOption(options);
    // timer = setInterval(doing, 100);
    // 设置图表实例的配置项和数据
    // chart.setOption(options, true)
    chart.on("mouseover", function (params) {
      // stopTimer();
      timer && clearInterval(timer);
    });
    chart.on("mouseout", function (params) {
      // startTimer();
      // timer = setInterval(doing, 100);
    });
   
    // 组件卸载
    return () => {
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      timer && clearInterval(timer);
      chart.dispose();
    };
  }, [selectedIndex, typeTabs]);


  const initEcharts = () => {
    const sortData=screenList?.sort((m,n)=>n?.volume-m?.volume)
    const xData = sortData?.map((item) => item.goodsType);
      
    const total=screenList.reduce((m, n) => {
      return m + (n.volume-0);
    },0);
    const sData = sortData?.map((m, i) => ({
      name: m?.goodsType,
      value: m?.volume,
      label: {
        show: true,
        position: "top",
        formatter: function ({name, value }) {
          
          console.log(total,'==total');
          
          var str = "{a|" + (value*100/total).toFixed(2) +"%" +"}";
          return str;
        },
        rich: {
          a: {
            fontSize: 12 * rateZoom,
            color: "#fff",
            align: "center",
            height: 20,
          },
        },
      },
    }));
    options.series[0].itemStyle = {
      normal: {
        color: function (params) {
          var colorList = [
            "rgba(51, 214, 149, 0.49)",
            "rgba(0, 139, 246, 0.49)",
            "rgba(0, 139, 246, 0.49)",
            "rgba(0, 139, 246, 0.49)",
            "rgba(0, 139, 246, 0.49)",
            "rgba(0, 139, 246, 0.49)",
          ];
          var bottomColorList = [
            "rgba(102, 195, 1, 0.07)",
            "rgba(0, 102, 255, 0.07)",
            "rgba(0, 102, 255, 0.07)",
            "rgba(0, 102, 255, 0.07)",
            "rgba(0, 102, 255, 0.07)",
            "rgba(0, 102, 255, 0.07)",
            "rgba(0, 102, 255, 0.07)",
          ];
          return {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color:
                  bottomColorList[params.dataIndex] ||
                  "rgba(0, 139, 246, 0.49)",
              },
              {
                offset: 0.5,
                color: colorList[params.dataIndex] || "rgba(0, 102, 255, 0.07)",
              },
            ],
            global: false,
          };
        },
      },
    };
    echarts.init(chartRef.current).setOption(
    {...options,xAxis:{...options.xAxis,data:xData},series:[{...options.series[0],data:sData}]})
  };
  return <div style={{ width: "100%", height: "100%" }} ref={chartRef}></div>;
}
