import { useEffect, useState } from "react"
import Map from "./Map.js"
import TopModule from "@/components/TopModule"
// rightModule
import RightModule from '@/components/LargeScreenModule/rightModule'
import LeftModule from '@/components/LargeScreenModule/leftModule'
import end_port from "@/assets/end_port.svg";
import start_port from '@/assets/start_port.svg';
import "./index.scss"
import { bigscreen } from "@/api"
import {currentEnvirement}from "@/utils/common"
export default function LargeScreen() {
  const [info, setinfo] = useState({});

  let rateZoom = 1;

  if (currentEnvirement()=="PC"&&window.screen.width>1920) {
    rateZoom = 2;
  }


  useEffect(() => {
    if (!localStorage.getItem("uid")) {
      window.location.href = "/login";
    }
    getbigscreen()
  }, [])
  const getbigscreen = async () => {
    try {
      const { data } = await bigscreen();

      setinfo(data)
    } catch (error) {
      console.log('bigscreen-大屏左面信息', error);
    }
    // debugger
  }
  return (
    <div className="largescreen_container">
      <div className="echarts_header">
        <TopModule />
      </div>
      <div className="map_container">
        <Map rateZoom={rateZoom} />
      </div>
      <div className='pictureLayerLeft'>
        <LeftModule info={info} />
      </div>
      <div className='pictureLayerRight'>
        {info?.place && <RightModule info={info} rateZoom={rateZoom} />}
      </div>
      <div className="bottom_tips">
        <div className="large_port">
          <img className="tips_img" src={end_port} />
          <span>国内大型港口</span>
        </div>
        <div className="origin_goods">
          <img className="tips_img" src={start_port} />
          <span>货物来源地</span>
        </div>
      </div>
    </div>
  )
}