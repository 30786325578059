L.AnimatedMarker = L.Marker.extend({
  options: {
    distance: 200,
    interval: 1000,
    autostart: true,
    onEnd: function () { },
    clickable: false
  },
  // 初始化
  initialize: function (latlngs, options) {

  },
  // css3 动画
  _chunk: function (latlngs) {

  },
  onAdd: function (map) {

  },
  animate: function () {

  },
  start: function () {

  },
  stop: function () {

  },
  setLine: function (latlngs) {

  }
})
const AnimatedMarker = L.animatedMarker = function (latlngs, options) {
  return L.AnimatedMarker(latlngs, options)
}
export { AnimatedMarker }