import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import 'echarts-gl';
import "@/index.scss"
export default function warehouseAddressRight({ data,  }) {
    const option = getPie3D(
        data,
        90000.8
    )
    // console.log(productQuantities, "3D动态图")


    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    function getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
        // 计算
        let midRatio = (startRatio + endRatio) / 2;

        let startRadian = startRatio * Math.PI * 2;
        let endRadian = endRatio * Math.PI * 2;
        let midRadian = midRatio * Math.PI * 2;

        // 如果只有一个扇形，则不实现选中效果。
        // if (startRatio === 0 && endRatio === 1) {
        //     isSelected = false;
        // }
        isSelected = false;
        // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
        k = typeof k !== 'undefined' ? k : 1 / 3;

        // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
        let offsetX = isSelected ? Math.sin(midRadian) * 0.1 : 0;
        let offsetY = isSelected ? Math.cos(midRadian) * 0.1 : 0;

        // 计算高亮效果的放大比例（未高亮，则比例为 1）
        let hoverRate = isHovered ? 1.05 : 1;

        // 返回曲面参数方程
        return {
            u: {
                min: -Math.PI,
                max: Math.PI * 3,
                step: Math.PI / 32,
            },

            v: {
                min: 0,
                max: Math.PI * 2,
                step: Math.PI / 20,
            },

            x: function (u, v) {
                if (u < startRadian) {
                    return offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            y: function (u, v) {
                if (u < startRadian) {
                    return offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                if (u > endRadian) {
                    return offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate;
                }
                return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
            },

            z: function (u, v) {
                if (u < -Math.PI * 0.5) {
                    return Math.sin(u);
                }
                if (u > Math.PI * 2.5) {
                    return Math.sin(u) * h * 0.1;
                }
                return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
            },
        };
    }

    // 生成模拟 3D 饼图的配置项
    function getPie3D(pieData, internalDiameterRatio) {
       
        let series = [];
        let sumValue = 0;
        let startValue = 0;
        let endValue = 0;
        let legendData = [];
        let k =
            typeof internalDiameterRatio !== 'undefined'
                ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
                : 1 / 3;

        // 为每一个饼图数据，生成一个 series-surface 配置
        for (let i = 0; i < pieData.length; i++) {
            sumValue += pieData[i].value;

            let seriesItem = {
                name: typeof pieData[i].name === 'undefined' ? `series${i}` : pieData[i].name,
                type: 'surface',
                parametric: true,
                wireframe: {
                    show: false,
                },
                pieData: pieData[i],
                pieStatus: {
                    selected: false,
                    hovered: false,
                    k: 1 / 10,
                },
            };

            if (typeof pieData[i].itemStyle != 'undefined') {
                let itemStyle = {
                    color: '',
                };

                // typeof pieData[i].itemStyle.color != 'undefined' ? (itemStyle?.color = pieData[i].itemStyle.color) : null;
                // typeof pieData[i].itemStyle.opacity != 'undefined'
                //     ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
                //     : null;

                // seriesItem.itemStyle = itemStyle;
            }
            series.push(seriesItem);
        }

        // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
        // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
        for (let i = 0; i < series.length; i++) {
            endValue = startValue + series[i].pieData.value;

            series[i].pieData.startRatio = startValue / sumValue;
            series[i].pieData.endRatio = endValue / sumValue;
            series[i].parametricEquation = getParametricEquation(
                series[i].pieData.startRatio,
                series[i].pieData.endRatio,
                false,
                false,
                k,
                series[i].pieData.value
            );

            startValue = endValue;

            legendData.push(series[i].name);
        }

        // // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
        series.push({
            name: 'mouseoutSeries',
            type: 'surface',
            parametric: true,
            wireframe: {
                show: false,
            },
            itemStyle: {
                opacity: 0.1,
                color: '#D8D8D8',
            },
            parametricEquation: {
                u: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20,
                },
                v: {
                    min: 0,
                    max: Math.PI,
                    step: Math.PI / 20,
                },
                x: function (u, v) {
                    return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 3.4;
                },
                y: function (u, v) {
                    return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 3.4;
                },
                z: function (u, v) {
                    return Math.cos(v) > 0 ? -30.5 : -53;
                },
            },
        });

        // // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
        series.push({
            name: 'mouseoutSeries',
            type: 'surface',
            parametric: true,
            wireframe: {
                show: false,
            },
            itemStyle: {
                opacity: 0.1,
                color: '#007BA2',
            },
            parametricEquation: {
                u: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20,
                },
                v: {
                    min: 0,
                    max: Math.PI,
                    step: Math.PI / 20,
                },
                x: function (u, v) {
                    return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 3.6;
                },
                y: function (u, v) {
                    return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 3.6;
                },
                z: function (u, v) {
                    return Math.cos(v) > 0 ? -53 : -73;
                },
            },
        });
        series.push({
            name: 'mouseoutSeries',
            type: 'surface',
            parametric: true,
            wireframe: {
                show: false,
            },
            itemStyle: {
                opacity: 0.1,
                color: '#E1E8EC',
            },
            parametricEquation: {
                u: {
                    min: 0,
                    max: Math.PI * 2,
                    step: Math.PI / 20,
                },
                v: {
                    min: 0,
                    max: Math.PI,
                    step: Math.PI / 20,
                },
                x: function (u, v) {
                    return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 2.2;
                },
                y: function (u, v) {
                    return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 2.2;
                },
                z: function (u, v) {
                    return Math.cos(v) > 0 ? -7 : -7;
                },
            },
        });

        // 准备待返回的配置项，把准备好的 legendData、series 传入。
        let option = {
            tooltip: {
                trigger: 'axis',
                confine: true,// 或者 '#main' 如果你的图表容器有一个 ID  
                axisPointer: {
                    type: 'cross'
                }
            },
            //animation: false,
            legend: {
                type: "scroll",
                orient: 'vertical',
                pageIconColor: '#1b9aee',
                pageIconInactiveColor: '#7f7f7f', 
                pageTextStyle: {
                    color: "#fff", // 可以根据需求调整样式
                },
                left: '73%',
                top: '29%',
                itemHeight: 3,
                itemWidth: 9,
                textStyle: {
                    fontSize: 12,
                    color: '#C6D1DB',
                },
                data: legendData,
                formatter: (params) => {
                    let obj = {
                        name: '',
                        value: ''
                    }
                    option?.series?.map((item, index) => {

                        if (params === item.name) {
                            obj = {
                                name: params,
                                value: option?.series[index]?.pieData?.value
                            }
                            return obj;
                        }
                    })
                    return obj.name + '  ' + obj.value; //左侧数据
                },
            },
            xAxis3D: {},
            yAxis3D: {},
            zAxis3D: {},
            grid3D: {
                viewControl: {
                    // autoRotate: true,
                    distance: 70,
                    alpha: 20,
                    beta: 2,
                    autoRotate: true, // 自动旋转
                    rotateSensitivity: 1,
                    zoomSensitivity: 0,
                    panSensitivity: 0,

                },
                position: {
                    top: '-100%' // 假设这个库支持这样的配置  
                },
                left: 'left',
                width: '100%',
                show: false,
                boxHeight: 10,
            },
            series: series,
        };
        return option;
    }
    const colors = [
        "#FFD15C",
        "#20E6A4",
        "#088EA9",
        "#07D1FA",
        "#124E91",
        "#BDC414",
        "#C8CCA5",
    ];
    const chartRef2 = useRef()
    var total = 0;
    useEffect(() => {
        // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
        const chart = echarts.init(chartRef2.current)

        // 设置图表实例的配置项和数据
        chart.setOption(option, true)

        setInterval(function () {
            //用setInterval做动画感觉有问题
            // draw();
        }, 100);
        // 组件卸载
        return () => {
            // myChart.dispose() 销毁实例。实例销毁后无法再被使用
            chart.dispose()
        }
    }, [data,])
    return (
        // <div style={{ width: "200%", height: "270%",marginTop:'-28%',marginLeft:'-75%' }} ref={chartRef2}></div>
        <div style={{ width: "130%", height: "120%", marginTop: '-8%', marginLeft: '-30%' }} ref={chartRef2}></div>
    );
}
