import React, { useEffect, useState } from 'react';
import { Carousel, Tabs } from "antd"
import riseDataImg from '@/assets/riseDataImg.png'
import InventoryDataImg from '@/assets/inventoryDataImg.png'
import HistogramChart from "./HistogramChart"
import CountUp from "../react-countUp/react-countup/count/index"
import LineDataChart from "./LineDataChart"
import PieDataChart from "./PieDataChart"
import './leftModule.scss';

import ScrollBoard from "@/pages/Cooperator/components/ScrollBoard"
const clientList = [
  { name: "深圳市势可科技有限公司", price: "5,894,750.00" },
  { name: "云南丽拓农业有限公司", price: "2,832,500.00 " },
  { name: "山东天酵源生物科技有限公司", price: "2,598,640.00 " },
  { name: "济宁鲁青生物科技有限公司", price: "2,332,440.00 " },
  { name: "莘县鲁恒基质销售有限公司", price: "2,047,280.00 " },
  { name: "山东芯喜乐生物科技有限公司", price: "1,980,000.00 " },
  { name: "云南伍田农业科技有限公司", price: "1,966,300.00 " },
  { name: "山东重农农业科技有限公司", price: "1,885,368.00 " },
  { name: "富蓝农业科技(江苏)有限公司", price: "1,677,630.00 " },
  { name: "佛山富根农业科技有限公司", price: "1,665,017.00 " },
];
const supplierList = [
  { name: "AS NURME TURVAS", price: "17,446,844.80 " },
  { name: "ASB Greenworld", price: "8,811,998.66" },
  { name: "Hansa", price: "8,658,314.50" },
  { name: "Torfo", price: "7,132,094.48" },
  { name: "Agaris", price: "4,289,809.13" },
  { name: "Plantaflor ", price: "4,289,809.13 " },
  { name: "SIA ETEPEK", price: "2,769,480.00" },
  { name: "LLC RPC", price: "1,791,360.00" },
  { name: "NOVARBO OY", price: "1,401,592.38" },
  { name: "SVT", price: "476,460.36" },
  { name: "Vaighai", price: "419,760.00" },
  { name: "Trellis", price: "210,312.00 " },
  { name: "Zibu", price: "108,320.58 " },

];
export default function LeftTradeOverview(props) {
  const { tradeVolume, inventoryQuantity, categoryTotal, operate, currentSubsidiary, categoryTotalMoney, tradeName, inventoryName, } = props.data

  useEffect(() => {
    // console.log('===-typetabs==', props.typeTabs);

  }, [props.typeTabs])


  return (
    <div className='cooperator_leftData'>
      <div className='leftDataTitle'></div>
      <div className='tradeVolume'>{tradeName}</div>
      <div className='Trade'>
        {tradeVolume.map((item, index) => {
          if (item === ',') {
            return (
              <span className='comma' key={item + index} >
                {item}
              </span>
            );
          } else if (item === '.') {
            return (
              <span className='comma' key={item + index} >
                {item}
              </span>
            );
          } else {
            return (
              <CountUp
                start={0}
                key={item + '-' + index}
                end={Number(item)}
                className='TradeData'
              // delay={2}
              // duration='30000'
              />
            );
          }
        })}

      </div>

      <div className='cooperator_moth_data_container' >
              <div className='monthData'>
                环比四月进口贸易额(元)
                </div>
              
              <div className='riseData'> 
                9237025.18
                <img src={riseDataImg} alt="" className='cooperator_riseDataImg' />

                </div>
              </div>
      <div className='inventoryData'>
        <div className='inventoryDataLeft'>
          <div className='inventoryDataLeftBorder'></div>
          <div className='inventoryDataConter'>
            <div className='inventoryDataConterOne'>{inventoryName}</div>
            <div className='inventoryDataConterTwo'>{inventoryQuantity}</div>
            
          </div>
        </div>
        <div className='InventoryDataBoxImg'>
          <img src={InventoryDataImg} alt="" className='InventoryDataImg' />
        </div>
      </div>
      <div>
        <div className='category'> 月度采购额（万元）</div>
        <div style={{ display: 'flex' }} className='categoryBorder'>
          <div className='categoryBorderLeft'></div>
          <div className='categoryBorderCenter'></div>
          <div className='categoryBorderRight'></div>
        </div>
      </div>
      <div className='HistogramChart'>
        <HistogramChart key={props?.typeTabs} currentSubsidiary={props.currentSubsidiary} typeTabs={props?.typeTabs} data={categoryTotal} />
      </div>
      <div>
        <div className='category'> 供应商排名</div>
        <div style={{ display: 'flex' }} className='categoryBorder'>
          <div className='categoryBorderLeft'></div>
          <div className='categoryBorderCenter'></div>
          <div className='categoryBorderRight'></div>
        </div>
      </div>
      <div className='cooperator_left_bottom_tab'>
      <ScrollBoard configData={supplierList} />
      </div>
   
      {/* <Tabs
      className="cooperator_left_bottom_tab"
      tabBarStyle={{  color: '#999', border: 'none' }}
        items={[
          {
            key: '1',
            label: '供应商排名',
            children:
              <div className='PieDataChart'>

                <ScrollBoard configData={supplierList} />
              </div>,
          },
       
        ]}
      >

      </Tabs> */}


    </div>
  );
}