import React, { useEffect, useState } from 'react';
import './rightModule.scss';
//rightInventoryOverview
import RightInventoryOverview from './rightInventoryOverview'
import RightTradeOverview from './rightTradeOverview'
export default function rightModule({ info, rateZoom, typeTabs, setshowVedioInfo, currentSubsidiary }) {
  const { place, variety, port } = info
  const [data, setdata] = useState({
    my: {

      OverviewVal: {
        transit: '1060',
        inventory: '527',
        entrust: '6000',
      },
      OverviewOne: {
        xAxisData: [
          "铜矿",
          "铅锌矿",
          "银矿",
          "铝矿",
          "铅精矿",
        ],
        legendData: ["在途货物", "库存货物", "委托加工",],
        seriesData: [
          {
            name: "在途货物",
            type: "bar",
            stack: "总量",
            barWidth: "11px",
            data: [6700, 3760, 2440, 680, 430,],
            // markArea: areaStyle,
          },
          {
            name: "库存货物",
            type: "bar",
            stack: "总量",
            data: [690, 1480, 590, 260, 900,],
            // markArea: areaStyle,
          },
          {
            name: "委托加工",
            type: "bar",
            stack: "总量",
            data: [3230, 1340, 50, 1350, 30,],
            // markArea: areaStyle,
          },

        ],
      },
      //港口/堆场库存占比

      Proportion: {
        xAxisData: [
          "防城港",
          "宁波港",
          "连云港",
          "黄埔港",
          "青岛港",
        ],
        legendData: ["在途", "库存",],
        seriesData: [{
          name: '在途',
          data: [1128, 564, 752, 376, 940,],
          type: 'bar',
          barWidth: '10%',
          // itemStyle: {
          //   normal: {
          //     color: function (params) {
          //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
          //       return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
          //     }
          //   }
          // },
        }, {
          name: '库存',
          data: [444, 222, 296, 148, 370,],
          type: 'bar',
          barWidth: '10%',
          // itemStyle: {
          //   normal: {
          //     color: function (params) {
          //       return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
          //     }
          //   }
          // },
        },
        ]
      },
      warningData: {
        header: ['提单号', '港口', '到港日', '信用证到期日', '浮动盈亏'],
        data: [
          ['EGLV751231251417', '连云港', '03-12', '03-12<span style="color:#F5212D;">即将逾期</span>', '<span style="color:#F5212D;">+24000</span>'],
          ['MEDUBG122326', '连云港', '02-13', '03-13<span style="color:#F5212D;">即将逾期</span>', '<span style="color:#F5212D;">+45320</span>'],
          ['MEDUBG1231112', '防城港', '01-14', '--', '<span style="color:#50BA58;">-1500</span>'],
          ['W231233226', '黄埔港', '02-25', '04-26', '<span style="color:#F5212D;">+24040</span>'],
          ['MEDUL9231223', '黄埔港', '03-26', '04-17', '<span style="color:#50BA58;">-22300</span>'],
          ['MEDUL9123122', '黄埔港', '03-17', '--', '<span style="color:#F5212D;">+24200</span>'],
          ['MAERSK ALTAIR(散货)', '青岛港', '03-11', '05-19', '<span style="color:#50BA58;">正常</span>'],
          ['EGLV142299991948', '宁波港', '04-19', '--', '<span style="color:#F5212D">+19000</span>'],
          ['MEDUE3232232', '宁波港', '01-21', '--', '<span style="color:#F5212D">+1400</span>']
        ],
        columnWidth: [320, 100, 100, 200, 200, 120],
        align: ['left', 'left', 'left', 'left', 'left', 'right'],
        headerBGC: 'rgba(0, 123, 162, 0)',
        headerHeight: '40',
        oddRowBGC: 'rgba(0, 123, 162, 0)',
        evenRowBGC: 'rgba(0, 123, 162, 0)',
      }
    },
    gs: {
      OverviewVal: {
        transit: '3760',
        inventory: '1480',
        entrust: '1340',
      },
      OverviewOne: {
        xAxisData: [

          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
          "1月",
          "2月",

        ],
        legendData: ["在途货物", "库存货物"],
        seriesData: [
          {
            name: "在途货物",
            type: "bar",
            stack: "总量",
            barWidth: "11px",
            data: [188, 376, 564, '', 752, 300.8, 75.2, 376, 188, 188, 376, 148, 148],
            // markArea: areaStyle,
          },
          {
            name: "库存货物",
            type: "bar",
            stack: "总量",
            data: [74, 148, 222, '', 296, 118.4, 29.6, 148, 74, 74, 148, 148],
            // markArea: areaStyle,
          },


        ],
      },
      //港口/堆场库存占比
      Proportion: {
        xAxisData: [
          "防城港",
          "宁波港",
          "连云港",
          "黄埔港",
          "青岛港",
        ],
        legendData: ["在途货物", "库存货物", "委托加工",],
        seriesData: [{
          name: '在途货物',
          data: [1128, 564, 752, 376, 940,],
          type: 'bar',
          barWidth: '10%',
          // itemStyle: {
          //   normal: {
          //     color: function (params) {
          //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
          //       return color[0]//[params.dataIndex]; //返回对应索引位置的颜色值
          //     }
          //   }
          // },
        }, {
          name: '库存货物',
          data: [444, 222, 296, 148, 370,],
          type: 'bar',
          barWidth: '10%',
          // itemStyle: {
          //   normal: {
          //     color: function (params) {
          //       return color[1] //color[params.dataIndex]; //返回对应索引位置的颜色值
          //     }
          //   }
          // },
        }, {
          name: '委托加工',
          data: [402, 201, 268, 134, 335,],
          type: 'bar',
          barWidth: '10%',
          // itemStyle: {
          //   normal: {
          //     color: function (params) {
          //       // var colorList = ['#c23531','#2f4554','#61a0a8']; //自定义颜色列表
          //       return color[2] //color[params.dataIndex]; //返回对应索引位置的颜色值
          //     }
          //   }
          // },
        },


        ]
      },
      warningData: {
        header: ['提单号', '港口', '到港日', '信用证到期日', '浮动盈亏'],
        data: [
          ['EGLV751231251417', '连云港', '01-12', '01-28', '<span style="color:#F5212D;">+24000</span>'],
          ['MEDUBG122326', '连云港', '02-13', '01-17<span style="color:#F5212D;">即将逾期</span>', '<span style="color:#F5212D;">+45320</span>'],
          ['MEDUBG1231112', '防城港', '01-14', '--', '<span style="color:#50BA58;">-1500</span>'],
          ['W231233226', '黄埔港', '02-17', '02-18', '<span style="color:#F5212D;">+24040</span>'],
          ['MEDUL9231223', '黄埔港', '02-19', '--', '<span style="color:#50BA58;">-22300</span>'],
          ['MEDUL9123122', '黄埔港', '01-18', '--', '<span style="color:#F5212D;">+24200</span>'],
          ['MAERSK ALTAIR(散货)', '青岛港', '04-19', '02-28', '<span style="color:#50BA58;">正常</span>'],
          ['EGLV142299991948', '宁波港', '02-20', '--', '<span style="color:#F5212D">+19000</span>'],
          ['MEDUE3232232', '宁波港', '02-21', '--', '<span style="color:#F5212D">+1400</span>']
        ],
        columnWidth: [240, 100, 100, 200, 200, 170],
        headerBGC: 'rgba(0, 123, 162, 0)',
        headerHeight: '40',
        oddRowBGC: 'rgba(0, 123, 162, 0)',
        evenRowBGC: 'rgba(0, 123, 162, 0)',
      }
    },
  })
  useEffect(
    () => {
      if(currentSubsidiary?.label=="分公司B"){
        setdata({
          ...data, gs: {
            ...data.gs, OverviewVal: {
              transit: '6760',
              inventory: '3480',
              entrust: '140',
            }
          }
        })
      }
      if(currentSubsidiary?.label=="分公司C"){
        setdata({
          ...data, gs: {
            ...data.gs, OverviewVal: {
              transit: '3760',
              inventory: '9480',
              entrust: '1840',
            }
          }
        })
      }
      if(currentSubsidiary?.label=="分公司A"){
        setdata({
          ...data, gs: {
            ...data.gs, OverviewVal: {
              transit: '1760',
              inventory: '5480',
              entrust: '840',
            }
          }
        })
      }
     
      
      console.log(currentSubsidiary, 'currentSubsidiary==');

    }, [currentSubsidiary?.label])

  // 贸易总览
  return (
    <div style={{ width: '100%' }}>
      {/* 贸易总览 */}
      {typeTabs == "ck" ? 
      <RightInventoryOverview currentSubsidiary={currentSubsidiary} setshowVedioInfo={setshowVedioInfo} />
       : 
       <RightTradeOverview currentSubsidiary={currentSubsidiary} data={typeTabs == "my" ? data.my : data.gs} />}
      {/* <RightTradeOverview /> */}
      {/* 库存总览 */}
      {/* <RightInventoryOverview /> */}
    </div>
  );
}
