import React, { useEffect } from 'react';
// InventoryStatus  TransitInventory
import TransitInventory from "./transitInventory"
import InventoryStatus from "./inventoryStatus"
import Situation from "./situation"
import './leftInventoryOverview.scss';
import HlScrollBoard from "../hl_scrollBoard"
let config1 = {
    header: ['港口/堆场',  '数量'],
    data: [
        ['青岛仓(恒顺丰)',  '175(托)'],
        ['青岛仓(恒顺丰)',  '10200(包)'],
        ['青州仓(恒晟仓)',  '301(托)'],
        ['青州仓(恒晟仓)',  '14015(包)'],
        ['成都仓（捷瑞）',  '690(托)'],
        ['成都仓（捷瑞）',  '0(包)'],
        ['佛山仓(恒晟仓))',  '9728(托)'],
        ['佛山仓(恒晟仓))',  '41815(包)'],

    ],
    // index: true,///<span style="color:#e7bcf3;">行10列2</span>
    columnWidth: ['200', '90'],
    // align: ['center', 'center', 'center', 'center', 'center'],
    // carousel: 'page'
    headerBGC: 'rgba(0, 123, 162, 0)',
    headerHeight: '40',
    oddRowBGC: 'rgba(0, 123, 162, 0)',
    evenRowBGC: 'rgba(0, 123, 162, 0)',
    hoverPause: false,
    rowNum :4,
}

export default function leftInventoryOverview() {

    return (
        <div className='cooperator_inventoryBox'>
            <div className='inventoryBox_top'>
                <div className='inventoryOneImg'></div>
                <div className='inventoryBoxTotal'>
                    <div className='inventoryTotal'>库存总数</div>
                    <div className='inventoryNum'>76924</div>
                </div>
                <div className='InTransitTree_cooperator'>
                    <div className='InTransitTreeOne'>
                        <div className='measure'>托</div>
                        <div className='measureNub'> 9728 </div>
                    </div>
                    <div className='InTransitTreeTwo'>
                        <div className='measure'>小包</div>
                        <div className='measureNub'>41815</div>
                    </div>

                </div>
                
                    <div className='category'>货物库存详情</div>
                    <div style={{ display: 'flex' }} className='categoryBorder'>
                        <div className='categoryBorderLeft'></div>
                        <div className='categoryBorderCenter'></div>
                        <div className='categoryBorderRight'></div>
                    </div>
                    <div className='inventoryStatus'>
                        <InventoryStatus 
                        datax={['青岛(恒顺丰)', '青州(恒晟)']}
                        dataYT={['175', '301']}
                        dataYB={['10200', '14015']}
                        maxyLeft={500}
                        maxyRight={15000}
                        namePadding={30}
                         />
                        {/* <TransitInventory /> */  }
                    </div>
                    {/* <div className='category'>货物库存详情</div>
                    <div style={{ display: 'flex' }} className='categoryBorder'>
                        <div className='categoryBorderLeft'></div>
                        <div className='categoryBorderCenter'></div>
                        <div className='categoryBorderRight'></div>
                    </div> */}
                    <div className='inventoryStatus'>
                        <InventoryStatus 
                        namey="南方仓"
                        datax={[ '成都（捷瑞）', '佛山(恒晟)']}
                        dataYT={['690', '9728']}
                        dataYB={['0', '41815']} />
                        {/* <TransitInventory /> */}
                    </div>
                
            </div>
            {/* <div className='cooperator_leftInventoryOverview marginTopData'>
                <div className='operateTwo'></div>
                <div className='cooperator_scrollBoardBox'>
                    <HlScrollBoard configData={config1} />
                </div>
            </div> */}

        </div>
    );
}