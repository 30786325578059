import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import "@/index.scss"
export default function inventoryStatus({ datax = [], dataYT = [], dataYB = [], namey = '北方仓', maxyLeft = 10000, maxyRight = 50000, namePadding = 0 }) {

  const chartRef9 = useRef()
  var color = ['#0CD2E6', '#3751E6', '#FFC722', '#886EFF', '#008DEC', '#114C90', '#00BFA5'];

  const option = {
    tooltip: {
      trigger: 'axis',
      confine: true ,// 或者 '#main' 如果你的图表容器有一个 ID  
      axisPointer: {
          type: 'cross'
      }
  },
    legend: {       //对图形的解释部分
      // orient: 'vertical',
      data: ['托', '包'],
      top: 0,                                  //调整图例位置
      itemHeight: 6,
      right: 10,
      // y: 'center',
      icon: 'circle',
      textStyle: {                            //图例文字的样式
        color: '#839AAA',               //图例文字颜色
        fontSize: 12,                   //图例文字大小
        fontFamily: 'df'
      }

    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '4%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      axisLine: {
        show: true
      },
      axisLabel: {
        interval: 0, // 设置为 0 表示每个标签都显示
        // rotate: 45, // 旋转角度，使标签垂直显示
        margin: 8 // 标签之间的间距
      },

      data: datax
    },
    yAxis: [
      {
        type: 'value',
        // min: 0,
        max: maxyLeft,
        name: namey,
        splitLine: { show: false },
        nameTextStyle: {
          padding: [0, 0, 25, 10 + namePadding],

          color: '#D0D4D7', // 设置字体颜色为红色  
          fontSize: 16,     // 设置字体大小为 16  
          // fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
          fontFamily: 'df',
          // 其他样式设置，如 fontWeight: 'bold' 可以使字体加粗  
        },
        // 设置刻度间隔
        splitNumber: 1,
        axisLine: {
          show: false
        }
      },
      {
        type: 'value',
        min: 0,
        max: maxyRight,
        splitLine: { show: false },
        // 设置刻度间隔
        splitNumber: 1,
        axisLine: {
          show: false
        },
        position: 'right'
      }
    ],
    series: [
      {
        name: '托',
        type: 'bar',
        data: dataYT,
        barWidth: '18%',
        yAxisIndex: 0
      },
      {
        name: '包',
        type: 'bar',
        data: dataYB,
        barWidth: '18%',
        yAxisIndex: 1
      }
    ]
  };

  useEffect(() => {
    // 创建一个echarts实例，返回echarts实例。不能在单个容器中创建多个echarts实例
    const chart = echarts.init(chartRef9.current)

    // 设置图表实例的配置项和数据
    chart.setOption(option, true)
    setInterval(function () {
      //用setInterval做动画感觉有问题
      // draw();
    }, 100);
    // 组件卸载
    return () => {
      // myChart.dispose() 销毁实例。实例销毁后无法再被使用
      chart.dispose()
    }
  }, [])
  return (
    <div style={{ width: "100%", height: "100%" }} ref={chartRef9}></div>
  );
}
